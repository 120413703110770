<template>
  <div>
    {{ /*view selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      scrollable
      v-model="amendTradeModal"
      :title="$t('equity_bond.titles.amendment_trade')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('equity_bond.modals.amend_trade_modal_ok')"
      :cancel-title="$t('equity_bond.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      :no-enforce-focus="true"
    >
      <!-- :no-enforce-focus="true" sweeat alert text area çalışması için modalı böyle yapmalıyız -->

      <template #modal-header="{ close }">
        <h5 style="color: white;">{{$t('equity_bond.titles.amendment_trade')}}</h5>
        <p v-if="!checkVerify">
          <b-icon icon="exclamation-circle-fill" variant="warning"></b-icon>
          <b style="color: white;"> {{$t('equity_bond.modals.trade_not_verified')}} </b>
        </p>

        <p v-else>
          <b-icon icon="bookmark-check-fill" variant="success"></b-icon>
          <b style="color: white;"> {{$t('equity_bond.modals.verified_trade')}} </b>
        </p>
      </template>
      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <!-- /*******************************Order Details***************************************************************** */ -->
              <b-row class="mt-1">
                <b-card :title="$t('equity_bond.titles.order_details')" style="width: 100% !important;">
                  <hr />
                  <b-row>
                    <div class="d-flex" style="width: 100%;">
                      <!-- order date-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group :label="$t('equity_bond.trade_form.order_date')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_date')"
                            rules="required"
                          >
                            <flat-pickr
                              :placeholder="$t('equity_bond.trade_form.order_date')"
                              v-model="tradeForm.orderDate"
                              onClose="testClose"
                              class="form-control"
                              :config="orderDateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="
                              checkOrdersDate('Order Date')
                            "
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order time-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group :label="$t('equity_bond.trade_form.order_time')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_time')"
                            rules="required"
                          >
                            <cleave
                              id="orderTime"
                              v-model="tradeForm.orderTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="$t('equity_bond.trade_form.order_time')"
                              :state="errors.length > 0 ? false : null"
                              @blur.native="checkTime('Order Time')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration date -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group :label="$t('equity_bond.trade_form.order_duration_date')" v-if="orderDurationInputsVisible">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_duration_date')"
                            :rules="tradeForm.timeInForce == 'GTD' ? 'required' : ''"
                          >
                            
                            <flat-pickr
                              :placeholder="$t('equity_bond.trade_form.order_duration_date')"
                              v-model="tradeForm.orderDurationDate"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="orderDurationDataDisabled"
                              @on-change="
                              checkOrdersDate('Order Duration Date')
                            "
                              
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration time -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group :label="$t('equity_bond.trade_form.order_duration_time')" v-if="orderDurationInputsVisible">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_duration_time')"
                            :rules="tradeForm.timeInForce == 'GTD' ? 'required' : ''"
                          >

                            <cleave
                              id="orderDurationTime"
                              v-model="tradeForm.orderDurationTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="$t('equity_bond.trade_form.order_duration_time')"
                              :state="errors.length > 0 ? false : null"
                              @blur.native="checkOrdersTime('Order Duration Time')"
                              :disabled="orderDurationTimeDisabled"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                    <!-- entry type -->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.entry_type')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.entry_type')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.entryType"
                            :options="optEntryTypes"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_entry_type')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            @change="checkAmendEntryType()"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- buy/sell -->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.buy_sell')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.buy_sell')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.buySell"
                            :options="optbuySells"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_buy_sell')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- order type-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.order_type')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.order_type')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.orderType"
                            :options="optOrderTypes"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_order_type')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- time in force-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.time_in_force')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.time_in_force')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.timeInForce"
                            :options="optTimeInForces"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_time_in_force')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            @option:selected="changeTimeInForceType"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- cpty order date-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cpty_order_date')"
                        v-if="tradeForm.instrumentType == 'FI'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_order_date')"
                          :rules="
                            tradeForm.instrumentType == 'FI' ? 'required' : ''
                          "
                        >
                          <flat-pickr
                            :placeholder="$t('equity_bond.trade_form.cpty_order_date')"
                            v-model="tradeForm.cptyOrderDate"
                            onClose="testClose"
                            class="form-control"
                            :config="dateConfig"
                            style="border:1px solid #CD853F"
                            :state="errors.length > 0 ? false : null"
                            @on-change="
                              checkTraSettlCptyDate('Cpty Order Date')
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty order time-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cpty_order_time')"
                        v-if="tradeForm.instrumentType == 'FI'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_order_time')"
                          :rules="
                            tradeForm.instrumentType == 'FI' ? 'required' : ''
                          "
                        >
                          <cleave
                            id="orderTime"
                            v-model="tradeForm.cptyOrderTime"
                            class="form-control"
                            :raw="false"
                            :options="cleaveOptions.time"
                            style="border:1px solid #CD853F"
                            :placeholder="$t('equity_bond.trade_form.cpty_order_time')"
                            :state="errors.length > 0 ? false : null"
                            @blur.native="checkTime('Execution Time')"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Stop Price -->
                    <b-col
                      cols="3"
                      xl="3"
                      lg="3"
                      md="3"
                      sm="12"
                      v-if="
                        tradeForm.orderType == 'Stop' ||
                          tradeForm.orderType == 'Trailing Stop'
                      "
                    >
                      <b-form-group :label="$t('equity_bond.trade_form.stop_price')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.stop_price')"
                          :rules="
                            tradeForm.orderType == 'Stop'
                              ? 'required|min_value:0'
                              : '' || tradeForm.orderType == 'Trailing Stop'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <cleave
                            id="stopPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.stopPrice"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="$t('equity_bond.trade_form.stop_price')"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Trailing Amount -->
                    <b-col
                      cols="3"
                      xl="3"
                      lg="3"
                      md="3"
                      sm="12"
                      v-if="tradeForm.orderType == 'Trailing Stop'"
                    >
                      <b-form-group :label="$t('equity_bond.trade_form.trailing_amount')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.trailing_amount')"
                          :rules="
                            tradeForm.orderType == 'Trailing Stop'
                              ? 'required|min_value:0'
                              : ''
                          "
                        >
                          <b-form-input
                            id="trailingAmount"
                            v-model="tradeForm.trailingAmount"
                            name="trailingAmount"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.trailing_amount')"
                            autofocus
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <hr />
                    </b-col>
                    <div class="d-flex" style="width: 100%;">
                      <!-- enable connected OCO orders-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="enable OCO orders"
                          >
                            <b-form-checkbox
                              v-model="tradeForm.enableConnectedOCOOrders"
                            >
                              {{ $t('equity_bond.trade_form.enable_oco') }}
                            </b-form-checkbox>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Take Profit Limit -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.take_profit_limit')"
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.take_profit_limit')"
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="takeProfitLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.takeProfitLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.take_profit_limit')"
                              @blur.native="
                                checkEnableOCOInputs('takeProfitLimit')
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Stop -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.stop_loss_stop')"
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.stop_loss_stop')"
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossStop"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossStop"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.stop_loss_stop')"
                              @blur.native="
                                checkEnableOCOInputs('stopLossStop')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Limit -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.stop_loss_limit')"
                          v-if="tradeForm.enableConnectedOCOOrders"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.stop_loss_limit')"
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required|min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.stop_loss_limit')"
                              @blur.native="
                                checkEnableOCOInputs('stopLossLimit')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                  </b-row>
                </b-card>
              </b-row>
              <b-row>
                <!-- /******************************* Security Details***************************************************************** */ -->
                <b-col cols="4" sm="12" md="4" lg="4" xl="4" class="p-0">
                  <b-card
                    :title="$t('equity_bond.titles.security_details')"
                    style="width: 100% !important;"
                  >
                  <div class="d-flex" style="width: 100% !important;">
                      <!-- instrument type-->
                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group :label="$t('equity_bond.trade_form.instrument_type')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.instrument_type')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.instrumentType"
                              :options="optInstrumentTypes"
                              :class="
                                tradeForm.instrumentType == 'EQ'
                                  ? 'eq_color'
                                  : 'fi_color'
                              "
                              :clearable="false"
                              :placeholder="$t('equity_bond.trade_form.select_instrument_type')"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Prop-->
                      <!-- <b-col cols="12" xl="12" lg="12" md="12" sm="12" style="display: flex;justify-content:center;align-items:center;margin-top:0.5rem;">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="order date"
                          >
                            <b-form-checkbox
                              v-model="tradeForm.prop"
                            >
                              PROP
                            </b-form-checkbox>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col> -->
                    </div>
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cost_center')"
                      >
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cost_center')"
                          rules="required"
                        >

                    <v-select
                      v-model="tradeForm.costCenter"
                      :options="optCostCenters"
                      :class="
                        tradeForm.instrumentType == 'EQ'
                          ? 'eq_color'
                          : 'fi_color'
                      "
                      :clearable="false"
                      :placeholder="$t('equity_bond.trade_form.select_cost_center')"
                      :reduce="(val) => val.CostCenter"
                      label = 'CostCenter'

                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                  </b-form-group>
                    </b-col>

                    <!-- security desc-->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.security_desc')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.security_desc')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.securityDesc"
                            :options="optSecurityDescriptions"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_security_desc')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :loading="loadingSecurityDesc"
                            @option:selecting="selectedSecuritydesc"
                            
                          />
                          <!-- @search="
                              (search, loading) => {
                                loading(false);
                                searchSecuritydesc(search);
                              }
                            " -->
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- quantity -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.quantity')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.quantity')"
                          rules="required|min_value:0"
                        >
                          <cleave
                            id="quantity"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.quantity"
                            class="form-control"
                            :raw="true"
                            :options="cleaveQuantityOptions.number"
                            :placeholder="$t('equity_bond.trade_form.quantity')"
                            @blur.native="refreshAmount(true)"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- client price -->
                    <div class="d-flex">
                      <b-col cols="8" xl="8" lg="8" md="8" sm="6">
                        <b-form-group :label="$t('equity_bond.trade_form.client_price')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client_price')"
                            rules="required|min_value:0"
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientPrice"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.client_price')"
                              @blur.native="refreshAmount(true)"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4" xl="4" lg="4" md="4" sm="6" class="pl-0">
                        <b-form-group :label="$t('equity_bond.trade_form.price_type')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.price_type')"
                            rules="required"
                          >
                            <v-select
                              v-model="tradeForm.clientPriceType"
                              :options="optClientPriceTypes"
                              :clearable="false"
                              :placeholder="$t('equity_bond.trade_form.price_type')"
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                    <!-- amount -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.amount')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.amount')"
                        >
                          <cleave
                            id="amount"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.amount"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="$t('equity_bond.trade_form.amount')"
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- counterparty price -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.counterparty_price')"
                        v-if="tradeForm.instrumentType == 'FI'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.counterparty_price')"
                        >
                          <cleave
                            id="counterpartyPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.counterpartyPrice"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="$t('equity_bond.trade_form.counterparty_price')"
                            @blur.native="calculateCptyPrice"
                            style="border:1px solid #CD853F"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <div
                      class="d-flex"
                      style="width: 100% !important;"
                      v-if="tradeForm.instrumentType == 'FI'"
                    >
                      <!-- Clean-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="clean"
                          >
                            <b-form-checkbox v-model="tradeForm.clean">
                              {{$t('equity_bond.trade_form.clean')}}
                            </b-form-checkbox>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- accrued -->
                      <b-col cols="9" xl="9" lg="9" md="9" sm="12">
                        <b-form-group :label="$t('equity_bond.trade_form.accrued') + '*'" v-if="tradeForm.clean">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.accrued')"
                            :rules="tradeForm.clean && tradeForm.instrumentType == 'FI' ? 'required' : ''"
                          >
                          <cleave
                              id="accrued"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.accrued"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.accrued')
                              "
                              @blur.native="calculateAccrued"
                              style="border:1px solid #CD853F"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <!-- client -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.client')">
                        <div class="d-flex align-items-center">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client')"
                            rules="required"
                            style="width: 100%;"
                          >
                            <v-select
                              v-model="tradeForm.client"
                              :options="optClients"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.select_client')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              :loading="loadingClient"
                              :filterable="true"
                              @option:selecting="selectedClient"
                              
                            />
                  
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                          <b-dropdown
                            variant="link"
                            no-caret
                            :right="$store.state.appConfig.isRTL"
                            class="input-dropdown animate__animated animate__fadeIn"
                            v-if="this.searchedClients.length > 0"
                          >
                            <template #button-content class="p-1">
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                              />
                            </template>

                            <b-dropdown-item @click="seeFilteredClientModal">
                              <feather-icon icon="EyeIcon" />
                              <span class="align-middle ml-50">{{
                                $t("general_text.see_verified_clients")
                              }}</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </b-form-group>
                    </b-col>
                    <!-- client account -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.client_account')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.client_account')"
                          rules="required"
                        >
                          <b-form-input
                            id="clientAccount"
                            v-model="tradeForm.clientAccount"
                            name="clientAccount"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.client_account')"
                            autofocus
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- ISIN -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.isin')">
                        <validation-provider #default="{ errors }" name="ISIN">
                          <b-form-input
                            id="isin"
                            v-model="tradeForm.isin"
                            :name="$t('equity_bond.trade_form.isin')"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.isin')"
                            autofocus
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- exchange traded -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.exchange_traded')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.exchange_traded')"
                        >
                          <v-select
                            v-model="tradeForm.exchangeTraded"
                            :options="optExchangeTrades"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_exchange_traded')"
                            :reduce="(val) => val"
                            :taggable="true"
                            :loading="exchangeSelectBoxLoading"
                            @option:created="createNewExchangeTraded"
                            
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- counter party -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.counterparty')">
                        <div class="d-flex align-items-center">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.counterparty')"
                          style="width: 100%;"
                        >
                          <v-select
                            v-model="tradeForm.counterparty"
                            :options="optCounterParties"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_counterparty')
                            "
                            :reduce="(val) => val"
                            :loading="loadingCounterparty"
                            :filterable="true"
                            @option:selecting="selectedCounterparty"
                            
                          />
                
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                        <b-dropdown
                            variant="link"
                            no-caret
                            :right="$store.state.appConfig.isRTL"
                            class="input-dropdown animate__animated animate__fadeIn"
                            v-if="this.searchedCounterparties.length > 0"
                          >
                            <template #button-content class="p-1">
                              <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                              />
                            </template>

                            <b-dropdown-item @click="seeFilteredCounterpartyModal">
                              <feather-icon icon="EyeIcon" />
                              <span class="align-middle ml-50">{{
                                $t("general_text.see_verified_clients")
                              }}</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </b-form-group>
                    </b-col>
                    <!-- cpty account -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.cpty_account')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_account')"
                        >
                          <b-form-input
                            id="isin"
                            v-model="tradeForm.cptyAccount"
                            name="cpty account"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.cpty_account')"
                            autofocus
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- counter party trader -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.counterparty_trader')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.counterparty_trader')"
                        >
                          <v-select
                            v-model="tradeForm.counterpartyTrader"
                            :options="optCounterPartyTraders"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_counterparty_trader')"
                            :reduce="(val) => val"
                            label = 'Trader'
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- sfc user -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.sfc_user')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.sfc_user')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.scfUser"
                            :options="optScfUsers"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_sfc_user')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- acting capacity -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.acting_capacity')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.acting_capacity')"
                          rules="required"
                        >
                          <v-select
                            v-model="tradeForm.actingCapaticy"
                            :options="optActingCapacities"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_acting_capacity')"
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-card>
                </b-col>

                <b-col cols="8" sm="12" md="8" lg="8" xl="8" class="p-0">
                  <!-- /******************************* Trade Date & Time***************************************************************** */ -->
                  <b-col cols="12" sm="12" mf="12" lg="12" xl="12" class="pr-0">
                    <b-card
                      :title="$t('equity_bond.titles.trade_date_time')"
                      style="width: 100% !important;"
                    >
                      <div class="d-flex">
                        <!-- Trade Date -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.trade_date')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.trade_date')"
                            >
                              <b-input-group
                                :class="
                                  tradeForm.tradeDateName == 'Sunday' ||
                                  tradeForm.tradeDateName == 'Saturday'
                                    ? 'tradeDateName'
                                    : ''
                                "
                                :append="tradeForm.tradeDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t('equity_bond.trade_form.trade_date')
                                  "
                                  v-model="tradeForm.tradeDate"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  @on-change="
                                    checkTraSettlCptyDate('Trade Date')
                                  "
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- T+ -->
                        <b-col
                          cols="6"
                          xl="1"
                          lg="1"
                          md="1"
                          sm="12"
                          style="padding-left: 0.5rem !important; padding-right:0.5rem !important"
                        >
                          <b-form-group label="T+">
                            <validation-provider
                              #default="{ errors }"
                              name="T+"
                              rules="min_value:0"
                            >
                              <b-form-input
                                id="T+"
                                v-model.number="tradeForm.Tplus"
                                name="T+"
                                placeholder="T+"
                                autofocus
                                @change="arrangeTPlus"
                                type="number"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Settlement Date -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.settlement_date')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.settlement_date')
                              "
                            >
                              <b-input-group
                                :class="
                                  tradeForm.settlementDateName == 'Sunday' ||
                                  tradeForm.settlementDateName == 'Saturday'
                                    ? 'settlementDateName'
                                    : ''
                                "
                                :append="tradeForm.settlementDateName"
                              >
                                <flat-pickr
                                  :placeholder="
                                    $t('equity_bond.trade_form.settlement_date')
                                  "
                                  v-model="tradeForm.settlementDate"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                  @on-change="
                                    checkTraSettlCptyDate('Settlement Date')
                                  "
                                />
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- execution time -->
                        <b-col cols="6" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.execution_time')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.execution_time')
                              "
                            >
                              <cleave
                                id="executionTime"
                                v-model="tradeForm.executionTime"
                                class="form-control"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="
                                  $t('equity_bond.trade_form.execution_time')
                                "
                                :state="errors.length > 0 ? false : null"
                                @blur.native="checkTime('Execution Time')"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <div class="d-flex" style="width: 100%;">
                        <!-- cpty execution time -->
                        <b-col
                          cols="4"
                          xl="4"
                          lg="4"
                          md="4"
                          sm="12"
                          v-if="tradeForm.instrumentType == 'FI'"
                        >
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.cpty_execution_time')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.cpty_execution_time')
                              "
                            >
                              <cleave
                                id="cptyExecutionTime"
                                v-model="tradeForm.cptyExecutionTime"
                                class="form-control"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.cpty_execution_time'
                                  )
                                "
                                :state="errors.length > 0 ? false : null"
                                style="border:1px solid #CD853F"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                    </b-card>
                  </b-col>

                  <!-- /******************************* Trade Details***************************************************************** */ -->

                  <b-col cols="12" sm="12" mf="12" lg="12" xl="12" class="pr-0">
                    <b-card
                      title="Trade Details"
                      style="width: 100% !important;"
                    >
                    <div class="d-flex">
                        <!-- Client Rate -->
                        <b-col cols="5" xl="5" lg="5" md="5" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.client_rate')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client_rate')"
                              :rules="
                                tradeForm.calculateCommissionFromRate1
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <b-input-group append="Bps">
                                <b-input-group-prepend is-text>
                                  <b-form-checkbox
                                    plain
                                    name="checkbox-input"
                                    v-model="
                                      tradeForm.calculateCommissionFromRate1
                                    "
                                    v-b-tooltip.hover.top
                                    :title="$t('equity_bond.trade_form.client_rate_tooltip')"
                                  >
                                
                                </b-form-checkbox>
                                </b-input-group-prepend>

                                <cleave
                                  id="clientRate"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.clientRate"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="$t('equity_bond.trade_form.client_rate')"
                                  :disabled="
                                    !tradeForm.calculateCommissionFromRate1
                                  "
                                  @blur.native="calculateClientRateToComission"
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Client Commission -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.client_comission')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client_comission')"
                              :rules="
                                !tradeForm.calculateCommissionFromRate1
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="clientCommission"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientCommission"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.client_comission')"
                                :disabled="
                                  tradeForm.calculateCommissionFromRate1
                                "
                                @blur.native="calculateClientRate"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Client amount -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.client_amount')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client_amount')"
                              rules="min_value:0"
                            >
                              <cleave
                                id="clientAmmount"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientAmount"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.client_amount')"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <div class="d-flex">
                        <!-- Counterparty Rate -->
                        <b-col cols="5" xl="5" lg="5" md="5" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.counterparty_rate')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.counterparty_rate')"
                              :rules="
                                tradeForm.calculateCommissionFromRate2
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <b-input-group append="Bps">
                                <b-input-group-prepend is-text>
                                  <b-form-checkbox
                                    plain
                                    name="checkbox-input"
                                    v-model="
                                      tradeForm.calculateCommissionFromRate2
                                    "
                                    v-b-tooltip.hover.top
                                    :title="$t('equity_bond.trade_form.counterparty_rate_tooltip')"
                                  />
                                </b-input-group-prepend>

                                <cleave
                                  id="counterpartyRate"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.counterpartyRate"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="$t('equity_bond.trade_form.counterparty_rate')"
                                  :disabled="
                                    !tradeForm.calculateCommissionFromRate2
                                  "
                                  @blur.native="calculateCounterpartyRateToComission"
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- cpty comission -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.cpty_comission')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.cpty_comission')"
                              :rules="
                                !tradeForm.calculateCommissionFromRate2
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="cptyComission"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.cptyCommission"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.cpty_comission')"
                                :disabled="
                                  tradeForm.calculateCommissionFromRate2
                                "
                                @blur.native="calculateCounterpartyRate"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- counterparty amount -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.counterparty_amount')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.counterparty_amount')"
                              rules="min_value:0"
                            >
                              <cleave
                                id="counterpartyAmount"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.counterpartyAmount"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.counterparty_amount')"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <div class="d-flex">
                        <!-- profit -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.profit')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.profit')"
                              
                            >
                              <cleave
                                id="profit"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.profit"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.profit')"
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- order taken via -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.order_taken_via')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.order_taken_via')"
                            >
                              <v-select
                                v-model="tradeForm.orderTakenVia"
                                :options="optOrderTakenVias"
                                :clearable="false"
                                :placeholder="$t('equity_bond.trade_form.select_order_taken_via')"
                                :reduce="(val) => val"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order taken through -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.order_given_through')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.order_given_through')"
                            >
                              <v-select
                                v-model="tradeForm.orderGivenThrough"
                                :options="optOrderGivenThroughs"
                                :clearable="false"
                                :placeholder="$t('equity_bond.trade_form.select_order_given_through')"
                                :reduce="(val) => val"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- tax -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.tax')"
                            v-if="tradeForm.instrumentType == 'EQ'"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.tax')"
                              rules="min_value:0"
                            >
                              <b-form-input
                                id="tax"
                                v-model.number="tradeForm.tax"
                                name="tax"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.tax')"
                                autofocus
                                style="border: 1px solid #191970;"
                                @change="calculateTax"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <div>
                        <!-- note -->
                        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.note')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.note')"
                            >
                              <b-form-textarea
                                id="textarea-no-resize"
                                :placeholder="$t('equity_bond.trade_form.note')"
                                rows="3"
                                no-resize
                                v-model="tradeForm.note"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <div>
                        <!-- trade reporting needed -->
                        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="trade reporting needed"
                            >
                              <b-form-checkbox
                                v-model="tradeForm.tradeReportingNeeded"
                              >
                                {{$t('equity_bond.trade_form.trade_reporting_needed')}}
                              </b-form-checkbox>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                    </b-card>
                  </b-col>

                  <!-- /******************************* Settlement Details***************************************************************** */ -->
                  <b-col cols="12" sm="12" mf="12" lg="12" xl="12" class="pr-0">
                    <b-card
                      :title="$t('equity_bond.titles.settlement_details')"
                      style="width: 100% !important;"
                    >
                      <div class="d-flex">
                        <!-- UTI  -->
                        <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.uti')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.uti')"
                            >
                              <b-form-input
                                id="UTI"
                                v-model="tradeForm.uti"
                                name="UTI"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.uti')"
                                autofocus
                                :disabled="true"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Unique Link ID -->
                        <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.unique_link_id')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.unique_link_id')"
                            >
                              <b-form-input
                                id="uniqueLinkID"
                                v-model.number="tradeForm.uniqueLinkID"
                                type="number"
                                name="uniqueLinkID"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.unique_link_id')"
                                autofocus
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <div class="d-flex">
                        <!-- Trade Start  -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group label="Trade Start">
                            <validation-provider
                              #default="{ errors }"
                              name="trade start"
                            >
                              <flat-pickr
                                placeholder="Trade Start"
                                v-model="tradeForm.tradeStart"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                        <!-- Trade end -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group label="Trade End">
                            <validation-provider
                              #default="{ errors }"
                              name="trade end"
                            >
                              <flat-pickr
                                placeholder="Trade End"
                                v-model="tradeForm.tradeEnd"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                      </div>
                      <div class="d-flex">
                        <!-- Settle Start  -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group label="Settle Start">
                            <validation-provider
                              #default="{ errors }"
                              name="settle start"
                            >
                              <flat-pickr
                                placeholder="Settle Start"
                                v-model="tradeForm.settleStart"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                        <!-- Settle end -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group label="Settle End">
                            <validation-provider
                              #default="{ errors }"
                              name="settle end"
                            >
                              <flat-pickr
                                placeholder="Settle End"
                                v-model="tradeForm.settleEnd"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                      </div>
                      <div>
                        <!-- Trade Ref -->
                        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.trade_ref')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.trade_ref')"
                            >
                              <b-form-input
                                :disabled="true"
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.trade_ref')"
                                autofocus
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                    </b-card>
                  </b-col>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" /><b
                >{{$t('equity_bond.overlay_messages.amendment_message')}}</b
              >
            </p>
          </div>
        </template>
      </b-overlay>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            id="tradeAction-button"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            class="float-left mb-0"
            :disabled="actionsData == null || actionsData.length == 0"
          >
            <feather-icon
              icon="EyeIcon"
              size="16"
              style="margin-right: 0.2rem;"
            />

            <span class="text-nowrap">{{ $t('equity_bond.buttons.trade_actions') }}</span>
          </b-button>
          <b-popover
            target="tradeAction-button"
            placement="top"
            triggers="click"
            id="actions-popover"
          >
            <template #title>
              {{ $t('equity_bond.titles.trade_actions') }}
            </template>
            <b-table-simple hover small caption-top responsive bordered>
              <b-tbody>
                <b-tr v-for="(action, index) in actionsData" :key="index">
                  <td style="width: 20%;">
                    {{ action.type }}
                  </td>
                  <td style="width: 20%;">
                    {{ action.user }}
                  </td>
                  <td style="width: 30%;">
                    {{ action.date }}
                  </td>
                  <td style="width: 30%;">
                    {{ action.reason }}
                  </td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-popover>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="md"
            class="float-right"
            @click="tradeOkOperation"
          >
            {{$t('equity_bond.modals.amend_trade_modal_ok')}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            size="md"
            class="float-right mr-1"
            @click="resetTradeModal"
          >
            {{$t('equity_bond.modals.modal_close')}}
          </b-button>
        </div>
      </template>
      {{/****END*** amend for trades modal*/}}
    </b-modal>

    {{/** view filtered client modal*/}}
    <b-modal
      id="clientModal"
      v-model="viewFilteredClientModal"
      :title="$t('client_table_modal.filtered_clients')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetClientModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      scrollable
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedClients"
                selectable
                select-mode="single"
                @row-clicked="selectedClientFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="
                  $t('equity_bond.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered modal*/}}
    </b-modal>
    {{/** view filtered counterparty modal*/}}
    <b-modal
      id="counterpartyModal"
      v-model="viewFilteredCounterpartyModal"
      :title="$t('client_table_modal.filtered_counterparties')"
      :ok-title="$t('general_buttons.cancel')"
      @ok="resetCounterpartyModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      scrollable
      ok-only
      ok-variant="danger"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refClientsModal"
                class="position-relative"
                :items="searchedCounterparties"
                selectable
                select-mode="single"
                @row-clicked="selectedCounterpartyFromTable"
                responsive
                head-variant="light"
                :fields="clientTableColumns"
                show-empty
                :empty-text="
                  $t('equity_bond.general_messages.no_matching_recors_found')
                "
              >
                <template #cell(AccountName)="data">
                  <strong>{{ data.item.AccountName }}</strong>
                </template>

                <template #cell(AccountNumber)="data">
                  <strong>{{ data.item.AccountNumber }}</strong>
                </template>

                <template #cell(SettlementType)="data">
                  <strong>{{ data.item.SettlementType }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view filtered counterparty modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  BFormTimepicker,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import equityBondStoreModule from "./EquityBondStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required,
  alphaNum,
  email,
  max,
  max_value,
  min_value,
} from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    BFormTimepicker,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
    AppTimeline,
    AppTimelineItem,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const EQUITYBONDS_APP_STORE_MODULE_NAME = "equity&bond";
    // Register module
    if (!store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
      store.registerModule(
        EQUITYBONDS_APP_STORE_MODULE_NAME,
        equityBondStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EQUITYBONDS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},

  props: {
   
    searchedCounterparties: {
      type: Array,
        default: function() {
        return [];
      }
    },
    searchedClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCostCenters: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCounterParties: {
      type: Array,
        default: function() {
        return [];
      }
    }

},

  data() {
    return {
      allEquityBondsData: [],
      amendTradeModal: false,
      tradeModalLoading: false,
      loadingSecurityDesc: false,
      loadingClient: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      actionsData: null,
      checkVerify: false,
      orderDurationDataDisabled:true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      exchangeSelectBoxLoading:false,
      weekday: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell", "Sell Short"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optInstrumentTypes: ["EQ", "FI"],
      optSecurityDescriptions: [],
      optClientPriceTypes: [],
      optExchangeTrades: [],
      optCounterPartyTraders: [],
      optScfUsers: [],
      optActingCapacities: ["PRINCIPAL", "AGENT"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      searchedSecurityDesc: [],
      fetchedTradeData: {},
      oldTradeFormData: {},
      checkedHolidayDate: null,
      tradeForm: {
        costCenter:null,

        orderDurationDate: null,
        orderDurationTime: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderTime: null,
        cptyOrderDate: null,
        cptyOrderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        instrumentType: null,
        prop: false,
        securityDesc: null,
        quantity: null,
        clientPrice: null,
        clientPriceType: null,
        amount: null,
        counterpartyPrice: null,
        clean: false,
        accrued: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded: null,
        counterparty: null,
        cptyAccount: null,
        counterpartyTrader: null,
        scfUser: null,
        actingCapaticy: null,
        tradeDate: null,
        tradeDateName:null,
        settlementDate: null,
        settlementDateName:null,
        Tplus: null,
        executionTime: null,
        cptyExecutionTime: null,
        clientRate: null,
        clientCommission: null,
        clientAmount: null,
        counterpartyRate: null,
        tax: null,
        cptyCommission: null,
        counterpartyAmount: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        profit: null,
        tradeReportingNeeded: null,
        note: null,
        calculateCommissionFromRate1: true,
        calculateCommissionFromRate2: true,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
      },
      orderDateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        // onChange: function(selectedDates, dateStr, instance) {
        //   console.log(instance)
        // }
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 2,
          // novos
          numeralDecimalMarkAlternativeInput: '.',
          completeDecimalsOnBlur: true  
          // fim novos
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave6DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 6,
          numeralDecimalMarkAlternativeInput: '.',
          completeDecimalsOnBlur: true  
        },
      },
      cleaveQuantityOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 0,
          numeralIntegerScale: 0,
        },
      },
      viewFilteredClientModal: false,
      viewFilteredCounterpartyModal:false,
      clientTableColumns: [
        {
          key: "AccountName",
          label: this.$t("client_table_modal.account_name"),
          class: "text-center",
        },
        {
          key: "AccountNumber",
          label: this.$t("client_table_modal.account_code"),
          sortable: false,
          class: "text-center",
        },
        {
          key: "SettlementType",
          label: this.$t("client_table_modal.settlement_type"),
          sortable: false,
          class: "text-center",
        },
      ],
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchEquityBond"]),
    getArangeEquityBondTCUsers() {
      store
        .dispatch("equity&bond/fetchArangeEquityBondTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
            this.optClientPriceTypes = res.data.currencies;
            this.optExchangeTrades = res.data.exchangeTrades;

          } else if (res.data.info == "no_users") {
            this.errorMessage(res.data.message);
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondOrderTakenGivenThrough() {
      store
        .dispatch(
          "equity&bond/fetchArrangeEquityBondOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(this.$t('equity_bond.messages.not_found_given_order_data'));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondCurrencies() {
      store
        .dispatch("equity&bond/fetchArrangeEquityBondCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optClientPriceTypes = res.data.currencies;
          }  else if (res.data.info == "no_users") {
            this.errorMessage(this.$t('equity_bond.messages.user_error_no_users'));
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getObjectDiff(obj1, obj2) {
      const diff = Object.keys(obj1).reduce((result, key) => {
        if (!obj2.hasOwnProperty(key)) {
          result.push(key);
        } else if (_.isEqual(obj1[key], obj2[key])) {
          const resultKeyIndex = result.indexOf(key);
          result.splice(resultKeyIndex, 1);
        }
        return result;
      }, Object.keys(obj2));
      return diff;
    },
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      const self = this;
      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          this.$checkAccessRight("EquityBond", "Amend").then((res) => {
            if (res.data.valid) {
              this.checkJournalCreated("Amend", this.tradeForm.uti).then(
                (res) => {
                  if (res.data.info == true) {
                    // console.log(_.isEqual(this.oldTradeFormData, this.tradeForm))
                    if (
                      this.getObjectDiff(this.oldTradeFormData, this.tradeForm)
                        .length > 0
                    ) {
                      store
                        .dispatch("equity&bond/checkTradeVerifyOperation", {
                          auth: this.user,
                          trade: this.tradeForm,
                        })
                        .then((res) => {
                          if (res.data.info == "verified") {
                            self.tradeModalLoading = true;
                            this.$swal({
                              title: self.$t('equity_bond.titles.reverified_trade'),
                              text: self.$t('equity_bond.messages.entry_already_verified'),
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonColor: "#3085d6",
                              cancelButtonColor: "#d33",
                              confirmButtonText: self.$t('equity_bond.reason.continue'),
                              cancelButtonText: self.$t('equity_bond.reason.Cancel'),
                              customClass: {
                                confirmButton: "btn btn-success mr-1",
                                cancelButton: "btn btn-danger",
                                denyButton: "btn btn-primary",
                              },
                              showClass: {
                                popup: "animate__animated animate__fadeInDown",
                              },
                              hideClass: {
                                popup: "animate__animated animate__fadeOutUp",
                              },
                            }).then((result) => {
                              if (result.isConfirmed) {
                                store
                                  .dispatch(
                                    "equity&bond/removeTradeVerifyOperation",
                                    {
                                      auth: self.user,
                                      tcUti: self.tradeForm.uti,
                                    }
                                  )
                                  .then((res) => {
                                    if (res.data.info == "Pass") {
                                      self
                                        .tradeEnterReasonText()
                                        .then((res) => {
                                          if (res) {
                                            if (
                                              self.oldTradeFormData.ocoLink !=
                                                null &&
                                              self.oldTradeFormData.ocoLink !=
                                                ""
                                            ) {
                                              if (
                                                self.oldTradeFormData
                                                  .entryType == "Order" &&
                                                self.tradeForm.entryType ==
                                                  "Execution"
                                              ) {
                                                store
                                                  .dispatch(
                                                    "equity&bond/checkUpdateAmendment",
                                                    {
                                                      auth: self.user,
                                                      tradeData:
                                                        self.oldTradeFormData,
                                                    }
                                                  )
                                                  .then((res) => {
                                                    if (
                                                      res.data.info == "Pass"
                                                    ) {
                                                      //canceled trade situation
                                                    } else {
                                                      self.errorMessage(
                                                        res.data.message
                                                      );
                                                      self.tradeModalLoading = false;
                                                    }
                                                  })
                                                  .catch((error) => {
                                                    console.log(error);
                                                  });
                                              }
                                            }

                                            self.tradeModalLoading = true;
                                            
                                            //set and check trade data
                                            let tradeData = self.setTradeData();


                                            store
                                              .dispatch(
                                                "equity&bond/tradeAmendmentUpdate",
                                                {
                                                  auth: self.user,
                                                  oldTradeData:
                                                    self.oldTradeFormData,
                                                  tradeData: tradeData,
                                                }
                                              )
                                              .then((res) => {
                                                if (res.data.info == "Pass") {
                                                  self.tradeModalLoading = false;
                                                  self.resetTradeModal();
                                                  this.tradeUpdatedSuccessMessage();
                                                  this.$emit(
                                                    "updatedData",
                                                    true
                                                  );

                                                  self.amendTradeModal = false;
                                                } else {
                                                  self.errorMessage(
                                                    res.data.message
                                                  );
                                                  self.tradeModalLoading = false;
                                                }
                                              })
                                              .catch((error) => {
                                                console.log(error);
                                              });
                                          } else {
                                            self.tradeModalLoading = false;
                                          }
                                        });
                                    } else {
                                      self.errorMessage(res.data.message);
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              } else {
                                self.amendTradeModal = false;
                                self.exitAmendOperationMessage();
                              }
                            });
                          } else {
                            self.tradeModalLoading = true;
                            self.tradeEnterReasonText().then((res) => {
                              if (res) {
                                if (
                                  self.oldTradeFormData.ocoLink != null &&
                                  self.oldTradeFormData.ocoLink != ""
                                ) {
                                  if (
                                    self.oldTradeFormData.entryType ==
                                      "Order" &&
                                    self.tradeForm.entryType == "Execution"
                                  ) {
                                    store
                                      .dispatch(
                                        "equity&bond/checkUpdateAmendment",
                                        {
                                          auth: self.user,
                                          tradeData: self.oldTradeFormData,
                                        }
                                      )
                                      .then((res) => {
                                        if (res.data.info == "Pass") {
                                          //canceled trade situation
                                        } else {
                                          self.errorMessage(res.data.message);
                                          self.tradeModalLoading = false;
                                        }
                                      })
                                      .catch((error) => {
                                        console.log(error);
                                      });
                                  }
                                }
                                //set and check trade data
                                let tradeData = self.setTradeData();

                                store
                                  .dispatch(
                                    "equity&bond/tradeAmendmentUpdate",
                                    {
                                      auth: self.user,
                                      oldTradeData: self.oldTradeFormData,
                                      tradeData: tradeData,
                                    }
                                  )
                                  .then((res) => {
                                    if (res.data.info == "Pass") {
                                      self.tradeModalLoading = false;
                                      self.resetTradeModal();
                                      this.tradeUpdatedSuccessMessage();
                                      this.$emit("updatedData", true);

                                      self.amendTradeModal = false;
                                    } else {
                                      self.errorMessage(res.data.message);
                                      self.tradeModalLoading = false;
                                    }
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  });
                              } else {
                                self.tradeModalLoading = false;
                              }
                            });
                            // this.checkTradeVerifyOperationMessage(
                            //   res.data.message
                            // );
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    } else {
                      this.noChangesMessage();
                    }
                  } else {
                    this.checkJournalCreatedMessage(res.data.message);
                  }
                }
              );
            } else {
              this.amendTradeModal = false;
              this.notAuthToastMessage();
            }
          });
        } else {
          let notEnteredDatas = "";

          let checkRequiredDatas = _.map(
            this.$refs.tradeValidation.errors,
            (value, key) => ({ key, value })
          );

          if (checkRequiredDatas.length > 0) {
            let first = true;
            checkRequiredDatas.forEach((r) => {
              if (r.value.length > 0) {
                if (first) {
                  notEnteredDatas += r.key;
                  first = false;
                } else {
                  notEnteredDatas += "," + r.key;
                  first = false;
                }
              }
            });
            this.checkRequiredInputsMessage(notEnteredDatas, 20000);
          }
        }
      });
    },
    createNewExchangeTraded(value){
      this.exchangeSelectBoxLoading = true

      if(this.tradeForm.securityDesc != null) {
        axiosIns
              .post(`saveExchange`, {
                security: this.tradeForm.securityDesc,
                exchange: value,
              })
              .then((res) => {
         
              })
              .catch((error) => reject(error));
      }
      this.optExchangeTrades.push(value)

      if(this.tradeForm.exchangeTraded == null) {
        this.tradeForm.exchangeTraded = value
      }
      this.exchangeSelectBoxLoading = false
    },
    searchSecuritydesc(search) {
      if (search.length > 2) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingSecurityDesc = true;
          let securityTypeVal =
            this.tradeForm.instrumentType == "FI" ? "Bond" : "Equity";

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchSecuritydesc`, {
                auth: this.user,
                search: search,
                type: securityTypeVal,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optSecurityDescriptions = [];
                  this.searchedSecurityDesc = []
                  this.searchedSecurityDesc = res.data.resData
                  res.data.resData.forEach((data) => {
                    this.optSecurityDescriptions.push(data.SecurityTicker);
                  });

                  this.loadingSecurityDesc = false;
                } else {
                  this.notFoundMessage("security desc.");
                  this.loadingSecurityDesc = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedSecuritydesc(search) {
      this.tradeForm.isin = null;
      this.tradeForm.clientPriceType = null;
      this.tradeForm.exchangeTraded = null

      let selecting = this.searchedSecurityDesc.find(
        (s) => s.SecurityTicker === search
      );
      this.tradeForm.isin = selecting.SecurityIsin;
      this.tradeForm.clientPriceType = selecting.SecurityCcy;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedSecuritydescForExchange`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              // this.tradeForm.exchangeTraded = res.data.resData.Exchange;
              this.optExchangeTrades = [];
              if(res.data.resData.length>0){
                res.data.resData.forEach(element => {
                  if(element!=null){
                    this.optExchangeTrades.push(element)
                  }              });

              }
            }
          })
          .catch((error) => reject(error));
      });
    },
 

    selectedClient(search) {
      this.tradeForm.clientAccount = null

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;

    },
    selectedClientFromTable(client){
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === client.AccountName
      );
      this.tradeForm.client = client.AccountName
      this.tradeForm.clientAccount = client.AccountNumber;
      this.viewFilteredClientModal = false;
    },
    seeFilteredClientModal() {
      this.viewFilteredClientModal = true;
    },
    resetClientModal() {
      this.viewFilteredClientModal = false;
    },

    selectedCounterparty(search) {
      this.tradeForm.counterparty = null;
        this.tradeForm.cptyAccount = null;
        this.tradeForm.counterpartyTrader = null;

        
      let selecting = this.searchedCounterparties.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.cptyAccount = selecting.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
              this.optCounterPartyTraders = res.data.resTrader;

              let listTraders = []
              res.data.resTrader.forEach(element => {
                if(!listTraders.includes(element.Trader)){
                  listTraders.push(element.Trader)

                }

                
              });
              this.optCounterPartyTraders = listTraders
              
            }
          })
          .catch((error) => reject(error));
      });
    },
    selectedCounterpartyFromTable(cpty){
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      this.tradeForm.counterparty = cpty.AccountName
      this.tradeForm.cptyAccount = cpty.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: cpty.AccountName,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
            }
              this.viewFilteredCounterpartyModal = false;
          })
          .catch((error) => reject(error));
      });

    },
    seeFilteredCounterpartyModal() {
      this.viewFilteredCounterpartyModal = true;
    },
    resetCounterpartyModal() {
      this.viewFilteredCounterpartyModal = false;
    },
    convertNumberInputFormat() {
      if (this.tradeForm.amount != null) {
        this.tradeForm.amount = Number(this.tradeForm.amount);
      }

      if (this.tradeForm.clientCommission != null) {
        this.tradeForm.clientCommission = Number(
          this.tradeForm.clientCommission
        );
      }
      if (
        this.tradeForm.clientPrice != null &&
        this.tradeForm.clientPrice > 0
      ) {
        this.tradeForm.clientPrice = Number(this.tradeForm.clientPrice);
      }
      if (this.tradeForm.cptyCommission != null) {
        this.tradeForm.cptyCommission = Number(this.tradeForm.cptyCommission);
      }
      if (this.tradeForm.accrued != null) {
        this.tradeForm.accrued = Number(this.tradeForm.accrued);
      }

      if (this.tradeForm.counterpartyPrice != null) {
        this.tradeForm.counterpartyPrice = Number(
          this.tradeForm.counterpartyPrice
        );
      }
      if (this.tradeForm.counterpartyAmount != null) {
        this.tradeForm.counterpartyAmount = Number(
          this.tradeForm.counterpartyAmount
        );
      }
      if (this.tradeForm.clientAmount != null) {
        this.tradeForm.clientAmount = Number(this.tradeForm.clientAmount);
      }
      if (this.tradeForm.stopLossLimit != null) {
        this.tradeForm.stopLossLimit = Number(this.tradeForm.stopLossLimit);
      }
      if (this.tradeForm.stopLossStop != null) {
        this.tradeForm.stopLossStop = Number(this.tradeForm.stopLossStop);
      }
      if (this.tradeForm.takeProfitLimit != null) {
        this.tradeForm.takeProfitLimit = Number(this.tradeForm.takeProfitLimit);
      }
      if (this.tradeForm.stopPrice != null) {
        this.tradeForm.stopPrice = Number(this.tradeForm.stopPrice);
      }
    },
    arrangeInputFormat() {
      if (
        this.tradeForm.clientPrice != null &&
        this.tradeForm.clientPrice > 0
      ) {
        this.tradeForm.clientPrice = Number(this.tradeForm.clientPrice).toFixed(
          2
        );
      }

      // if (this.tradeForm.clientRate != null) {
      //   this.tradeForm.clientRate = this.formatPrice2(this.tradeForm.clientRate, 6)
      // }

      // if (this.tradeForm.counterpartyRate != null) {
      //   this.tradeForm.counterpartyRate = this.formatPrice2(this.tradeForm.counterpartyRate, 6)
      // }

      if (this.tradeForm.clientCommission != null) {
        this.tradeForm.clientCommission = Number(
          this.tradeForm.clientCommission
        ).toFixed(2);
      }

      if (this.tradeForm.cptyCommission != null) {
        this.tradeForm.cptyCommission = Number(
          this.tradeForm.cptyCommission
        ).toFixed(2);
      }

      if (this.tradeForm.amount != null) {
        this.tradeForm.amount = Number(this.tradeForm.amount).toFixed(2);
      }

      if (this.tradeForm.counterpartyPrice != null) {
        this.tradeForm.counterpartyPrice = Number(
          this.tradeForm.counterpartyPrice
        ).toFixed(2);
      }
      if (this.tradeForm.counterpartyAmount != null) {
        this.tradeForm.counterpartyAmount = Number(
          this.tradeForm.counterpartyAmount
        ).toFixed(2);
      }
      if (this.tradeForm.clientAmount != null) {
        this.tradeForm.clientAmount = Number(
          this.tradeForm.clientAmount
        ).toFixed(2);
      }
      if (this.tradeForm.stopLossLimit != null) {
        this.tradeForm.stopLossLimit = Number(
          this.tradeForm.stopLossLimit
        ).toFixed(2);
      }
      if (this.tradeForm.stopLossStop != null) {
        this.tradeForm.stopLossStop = Number(
          this.tradeForm.stopLossStop
        ).toFixed(2);
      }
      if (this.tradeForm.takeProfitLimit != null) {
        this.tradeForm.takeProfitLimit = Number(
          this.tradeForm.takeProfitLimit
        ).toFixed(2);
      }
      if (this.tradeForm.stopPrice != null) {
        this.tradeForm.stopPrice = Number(this.tradeForm.stopPrice).toFixed(2);
      }
      if (this.tradeForm.profit != null) {
        this.tradeForm.profit = Number(this.tradeForm.profit).toFixed(2);
      }
      if (this.tradeForm.accrued != null) {
        this.tradeForm.accrued = Number(this.tradeForm.accrued).toFixed(2);
      }
    },
    checkEnableOCOInputs(input) {
      if (input == "takeProfitLimit") {
        if (this.tradeForm.takeProfitLimit > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.stopLossStop > 0) {
              if (
                this.tradeForm.takeProfitLimit < this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(this.$t('equity_bond.messages.take_profit_lower_stop_price')
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
            if (
              this.tradeForm.takeProfitLimit > 0 &&
              this.tradeForm.stopLossLimit > 0
            ) {
              if (
                this.tradeForm.takeProfitLimit < this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(this.$t('equity_bond.messages.take_profit_lower_limit_price')
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
          } else if (
            this.tradeForm.buySell == "Sell" ||
            this.tradeForm.buySell == "Sell Short"
          ) {
            if (this.tradeForm.stopLossStop > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(this.$t('equity_bond.messages.take_profit_greater_stop_price')
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
            if (
              this.tradeForm.takeProfitLimit > 0 &&
              this.tradeForm.stopLossLimit > 0
            ) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(this.$t('equity_bond.messages.take_profit_greater_limit_price')
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(this.$t('equity_bond.messages.greater_then_zero_take_profit'));
        }
      } else if (input == "stopLossStop") {
        if (this.tradeForm.stopLossStop > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.stopLossStop > this.tradeForm.takeProfitLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t('equity_bond.messages.stop_loss_greater_profit_price')
                );
                this.tradeForm.stopLossStop = null;
              }
            }
          } else if (
            this.tradeForm.buySell == "Sell" ||
            this.tradeForm.buySell == "Sell Short"
          ) {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t('equity_bond.messages.stop_loss_lower_profit_price')
                );
                this.tradeForm.stopLossStop = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(this.$t("equity_bond.messages.greater_then_zero_stop_loss"));
        }
      } else if (input == "stopLossLimit") {
        if (this.tradeForm.stopLossLimit > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.stopLossLimit > this.tradeForm.takeProfitLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t('equity_bond.messages.stop_loss_limit_greater_profit_price')
                );
                this.tradeForm.stopLossLimit = null;
              }
            }
          } else if (
            this.tradeForm.buySell == "Sell" ||
            this.tradeForm.buySell == "Sell Short"
          ) {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t('equity_bond.messages.stop_loss_limit_lower_profit_price')
                );
                this.tradeForm.stopLossLimit = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(this.$t('equity_bond.messages.greater_then_zero_stop_loss_limit'));
        }
      }
    },
    
    refreshAmount(val = false){
      this.convertNumberInputFormat();
      this.calculateClientFaceValue()
      if(val){
        this.arrangeTaxRate();
      }
      this.calculateClientComission();
      this.calculateCounterpartyComission();
      this.calculateClientAmount();
      this.calculateCounterpartyAmount();
      this.calculateTCProfit();
      this.arrangeInputFormat();
    },

    calculateClientFaceValue() {
      if (this.tradeForm.quantity > 0 && this.tradeForm.quantity != null) {
        if (this.tradeForm.quantity.toString().indexOf(".") > -1) {
          this.tradeForm.quantity = Math.round(this.tradeForm.quantity);
        }
      }

      if (this.tradeForm.quantity > 0 && this.tradeForm.clientPrice > 0) {
        this.convertNumberInputFormat();
        if (this.tradeForm.instrumentType == "EQ") {
          let cal = this.tradeForm.quantity * this.tradeForm.clientPrice;
          this.tradeForm.amount = cal;
          // this.tradeForm.clientAmount = cal;
          // this.tradeForm.counterpartyAmount = cal;
        } else if (this.tradeForm.instrumentType == "FI") {
          let cal =
            (this.tradeForm.quantity * this.tradeForm.clientPrice) / 100;

          this.tradeForm.amount = cal;
          // this.tradeForm.clientAmount = cal;
          // this.tradeForm.counterpartyAmount = cal;
        }
      }
    },

    calculateAccrued() {
      this.convertNumberInputFormat();
      this.refreshAmount(false)
      this.arrangeInputFormat();
    },
    calculateCptyPrice(){
      this.convertNumberInputFormat();
      this.refreshAmount(false)
      this.arrangeInputFormat();
    },

    calculateClientRateToComission() {
      this.convertNumberInputFormat();
      this.refreshAmount(false)
      this.arrangeInputFormat();
    },

    calculateClientComission() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.clientRate > -1
      ) {
        this.convertNumberInputFormat()
        if (this.tradeForm.instrumentType == "EQ") {
          this.tradeForm.clientCommission =
            (this.tradeForm.amount * this.tradeForm.clientRate) / 10000;
         
        } else if (this.tradeForm.instrumentType == "FI") {

          let temp = this.tradeForm.amount;

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = Number(temp) + Number(this.tradeForm.accrued);
          }

          

          this.tradeForm.clientCommission =
          this.formatPrice2((Number(temp) * Number(this.tradeForm.clientRate)) / 10000,2);

            
        }
        
        if (this.tradeForm.clientCommission > -1) {
          this.tradeForm.clientAmount = this.tradeForm.amount;
          this.tradeForm.clientAmount =
            this.tradeForm.clientAmount + this.tradeForm.clientCommission


          this.tradeForm.profit = this.tradeForm.clientCommission
          
        }
      }
      this.arrangeInputFormat()
      
    },
    calculateCounterpartyRateToComission() {
      this.convertNumberInputFormat();
      this.refreshAmount(false)
      this.arrangeInputFormat();
    },
    calculateCounterpartyComission() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.counterpartyRate > -1
      ) {
        this.convertNumberInputFormat()
        if (this.tradeForm.instrumentType == "EQ") {
          this.tradeForm.cptyCommission =
            (this.tradeForm.amount * this.tradeForm.counterpartyRate) / 10000;
          this.tradeForm.cptyCommission == 0 ? null : this.tradeForm.cptyCommission 
        } else if (this.tradeForm.instrumentType == "FI") {
          let temp = this.calculateConterpartyFaceValue();

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = Number(temp) + Number(this.tradeForm.accrued);
          }

          this.tradeForm.cptyCommission =
            (Number(temp) * Number(this.tradeForm.counterpartyRate)) / 10000;
        }
        if (this.tradeForm.cptyCommission > -1) {
          this.tradeForm.counterpartyAmount = this.tradeForm.amount;
          this.tradeForm.counterpartyAmount =
            this.tradeForm.counterpartyAmount + this.tradeForm.cptyCommission;
          this.tradeForm.profit =
            this.tradeForm.clientCommission +
            this.tradeForm.cptyCommission * -1;
        }

        this.arrangeInputFormat()
      }
    },
    calculateClientRate() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.clientCommission > -1
      ) {
        this.convertNumberInputFormat()
        if (this.tradeForm.instrumentType == "EQ") {
          this.tradeForm.clientRate =
          this.formatPrice2((this.tradeForm.clientCommission / this.tradeForm.amount) * 10000, 6);

        } else if (this.tradeForm.instrumentType == "FI") {
          let temp = this.tradeForm.amount;

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = Number(temp) + Number(this.tradeForm.accrued);
          }

          this.tradeForm.clientRate =
          this.formatPrice2((this.tradeForm.clientCommission / temp) * 10000,6);
        }

        if (this.tradeForm.clientRate > -1) {
          this.tradeForm.clientAmount = this.tradeForm.amount;
          this.tradeForm.clientAmount =
            this.tradeForm.clientAmount + this.tradeForm.clientCommission;
          this.tradeForm.profit =
            this.tradeForm.clientCommission +
            this.tradeForm.cptyCommission * -1;
        }

        this.convertNumberInputFormat();
        this.calculateClientFaceValue()
        this.calculateClientAmount();
        this.calculateCounterpartyAmount();
        this.calculateTCProfit();
        this.arrangeInputFormat()
      }
    },
    calculateCounterpartyRate() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.cptyCommission > -1
      ) {
        this.convertNumberInputFormat()
        if (
          this.tradeForm.amount > 0 &&
          this.tradeForm.instrumentType == "EQ"
        ) {
          this.tradeForm.counterpartyRate =
          this.formatPrice2((this.tradeForm.cptyCommission / this.tradeForm.amount) * 10000,6);
        } else if (this.tradeForm.instrumentType == "FI") {
          let temp = this.calculateConterpartyFaceValue();

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = temp + this.tradeForm.accrued;
          }

          this.tradeForm.counterpartyRate =
          this.formatPrice2((this.tradeForm.cptyCommission / temp) * 10000,6);
        }

        if (this.tradeForm.counterpartyRate > -1) {
          this.tradeForm.counterpartyAmount = this.tradeForm.amount;
          this.tradeForm.counterpartyAmount =
            Number(this.tradeForm.counterpartyAmount) + Number(this.tradeForm.cptyCommission);
          this.tradeForm.profit =
          Number(this.tradeForm.clientCommission) +
          Number(this.tradeForm.cptyCommission) * -1;
        }
        this.convertNumberInputFormat();
        this.calculateClientFaceValue()
        this.calculateClientAmount();
        this.calculateCounterpartyAmount();
        this.calculateTCProfit();
        this.arrangeInputFormat()
      }
    },
    calculateConterpartyFaceValue() {
      if (this.tradeForm.quantity > 0) {
        this.convertNumberInputFormat()
        if (
          this.tradeForm.clientPrice > 0 &&
          this.tradeForm.instrumentType == "EQ"
        ) {
          // this.tradeForm.amount =
          // Number(this.tradeForm.quantity) * Number(this.tradeForm.clientPrice);
          return this.tradeForm.quantity * this.tradeForm.clientPrice;
        } else if (
          this.tradeForm.counterpartyPrice > 0 &&
          this.tradeForm.instrumentType == "FI"
        ) {
          // this.tradeForm.amount =
          //   (Number(this.tradeForm.quantity) * Number(this.tradeForm.counterpartyPrice)) / 100;
          return (
            (Number(this.tradeForm.quantity) * Number(this.tradeForm.counterpartyPrice)) / 100
          );
        } else {
          return 0;
        }
        
      }
    },
    calculateTax() {
      this.calculateClientFaceValue();
      this.calculateClientAmount();
      this.calculateCounterpartyAmount();
      this.calculateTCProfit();
    },
    calculateClientAmount() {
      let temp = 0;
      this.convertNumberInputFormat()
      if (this.tradeForm.amount > 0) {
        temp = Number(temp) + Number(this.tradeForm.amount);
      }

      if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
        if (this.tradeForm.instrumentType == "FI") {
          temp = Number(temp) + Number(this.tradeForm.accrued);
        }
      }

      if (this.tradeForm.clientCommission > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = Number(temp) + Number(this.tradeForm.clientCommission);
        } else {
          temp = Number(temp) - Number(this.tradeForm.clientCommission);
        }
      }

      if (this.tradeForm.tax > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = Number(temp) + Number(this.tradeForm.tax);
        } else {
          temp = Number(temp) - Number(this.tradeForm.tax);
        }
      }

      if (temp > 0) {
        this.tradeForm.clientAmount = temp;
        //this.tradeForm.counterpartyAmount = temp;
        this.arrangeInputFormat()
      } else {
        this.tradeForm.clientAmount = null;
      }
    },
    calculateCounterpartyAmount() {
      let temp = 0;
      this.convertNumberInputFormat()
      temp = temp + this.calculateConterpartyFaceValue();

      if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
        if (this.tradeForm.instrumentType == "FI") {
          temp = Number(temp) + Number(this.tradeForm.accrued);
        }
      }

      if (this.tradeForm.cptyCommission > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = Number(temp) + Number(this.tradeForm.cptyCommission);
        } else {
          temp = Number(temp) - Number(this.tradeForm.cptyCommission);
        }
      }

      if (this.tradeForm.tax > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = Number(temp) + Number(this.tradeForm.tax);
        } else {
          temp = Number(temp) - Number(this.tradeForm.tax);
        }
      }

      if (temp > 0) {
        //this.tradeForm.clientAmount = temp;
        this.tradeForm.counterpartyAmount = Number(temp);
        this.arrangeInputFormat()
      } else {
        this.tradeForm.counterpartyAmount = null;
      }
    },
    calculateTCProfit() {
      let temp = 0;
      if (
        this.tradeForm.clientAmount > 0 &&
        this.tradeForm.counterpartyAmount > 0
      ) {
        this.convertNumberInputFormat()
        if (this.tradeForm.buySell == "Buy") {
          this.tradeForm.profit =
          this.formatPrice2(
            this.tradeForm.clientAmount - this.tradeForm.counterpartyAmount,
          2
        );
        } else {
          this.tradeForm.profit =
          this.formatPrice2(
            -this.tradeForm.clientAmount + this.tradeForm.counterpartyAmount,
          2
        );
            
        }
        this.arrangeInputFormat()
      } else {
        this.tradeForm.profit = null;
      }
    },
    checkAmendEntryType() {
      if (this.tradeForm.ocoLink != "" && this.tradeForm.ocoLink != null) {
        if (
          this.oldTradeFormData.entryType == "Order" &&
          this.tradeForm.entryType == "Execution"
        ) {
          store
            .dispatch("equity&bond/checkAmendEntryType", {
              auth: this.user,
              ocoLink: this.tradeForm.ocoLink,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
              } else if (res.data.info == "not_found") {
                this.checkAmendEntryTypeMessage(
                  this.$t('equity_bond.messages.can_not_execute_before_create_journal')
                  
                );
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
    arrangeTaxRate() {
      if (this.tradeForm.instrumentType == "EQ") {
        axiosIns
          .post(`selectTexRateAsExchangeBuySell`, {
            auth: this.user,
            exchange: this.tradeForm.exchangeTraded,
            buySell: this.tradeForm.buySell,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              if (res.data.taxRate > 0) {
                this.tradeForm.tax = res.data.taxRate * this.tradeForm.amount;
              }
            }
          })
          .catch((error) => console.log(error));
      }
    },

    getAllSecurityDesc() {

axiosIns
    .post(`getAllVerifiedSecurities`)
    .then((res) => {
      if (res.data.length > 0) {
            this.optSecurityDescriptions = [];
            this.searchedSecurityDesc = [];
            this.searchedSecurityDesc = res.data;
            res.data.forEach((data) => {
              this.optSecurityDescriptions.push(data.SecurityTicker);
            });
          } else {
            this.notFoundMessage("security desc.");
          }
    })
    .catch((error) => console.log(error));
},

    getHolidays() {
      return new Promise((resolve, reject) => {
        axiosIns
          .post(`getHolidays`, this.user)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => reject(error));
      });
    },

    checkHolidaysDate(holidays, tradeDate) {
      let checkDate = tradeDate;
      let status = true;
      holidays.forEach((h) => {
        if (this.dateFormat(h.Date) == this.dateFormat(tradeDate)) {
          checkDate.setDate(checkDate.getDate() + 1);
          status = false;
          this.checkHolidaysDate(holidays, checkDate);
        }
      });
      if (status) {
        this.checkedHolidayDate = checkDate;
      }
    },

    arrangeTPlus() {
      if (
        this.tradeForm.tradeDate != null &&
        this.tradeForm.tradeDateName != null
      ) {
        if (this.tradeForm.Tplus > 0) {
          this.getHolidays().then((holidays) => {
            if (holidays.length > 0) {
              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.Tplus;
              tradeDate.setDate(tradeDate.getDate() + period);

              this.checkHolidaysDate(holidays, tradeDate);

              //found settlement date name
              let settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 2
                );
              } else if (settlementDateName == "Sunday") {
                this.checkedHolidayDate.setDate(
                  this.checkedHolidayDate.getDate() + 1
                );
              }

              //again check holiday
              this.checkHolidaysDate(holidays, this.checkedHolidayDate);

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(
                this.checkedHolidayDate
              );
              this.tradeForm.settlementDateName = this.weekday[
                this.checkedHolidayDate.getDay()
              ];
            } else {
              let tradeDate = new Date(
                this.MDYdateFormat(this.tradeForm.tradeDate)
              );

              let period = this.tradeForm.Tplus;

              tradeDate.setDate(tradeDate.getDate() + period);

              //found settlement date name
              let settlementDateName = this.weekday[tradeDate.getDay()];

              //check weekend settlement date
              if (settlementDateName == "Saturday") {
                tradeDate.setDate(tradeDate.getDate() + 2);
              } else if (settlementDateName == "Sunday") {
                tradeDate.setDate(tradeDate.getDate() + 1);
              }

              // set settlement date
              this.tradeForm.settlementDate = this.dateFormat(tradeDate);
              this.tradeForm.settlementDateName = this.weekday[
                tradeDate.getDay()
              ];
            }
          });
        }
      }
    },
    checkTraSettlCptyDate(type) {
      if (type == "Trade Date") {

        let tradeDate = new Date(this.MDYdateFormat(this.tradeForm.tradeDate));

        //set trade date name
        this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];

        //arrange as T+
        if (this.tradeForm.Tplus > 0) {
          this.arrangeTPlus();
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t('equity_bond.messages.trade_date_after_settlement_date')
          );
          this.tradeForm.tradeDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t('equity_bond.messages.trade_date_before_order_date')
          );
          this.tradeForm.tradeDate = null;
        }
        if (this.tradeForm.instrumentType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t('equity_bond.messages.trade_date_before_counterparty_order_date')
              
            );
            this.tradeForm.tradeDate = null;
          }
        }
      } else if (type == "Settlement Date") {


        let settleDate = new Date(this.MDYdateFormat(this.tradeForm.settlementDate));

        //set settle date name
        this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t('equity_bond.messages.trade_date_after_settlement_date')
          );
          this.tradeForm.settlementDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t('equity_bond.messages.trade_date_after_settlement_date')
            
          );
          this.tradeForm.settlementDate = null;
        }
        if (this.tradeForm.instrumentType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t('equity_bond.messages.settlement_date_before_counterparty_order_date')
            );
            this.tradeForm.settlementDate = null;
          }
        }
      } else if ("Cpty Order Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t('equity_bond.messages.trade_date_before_counterparty_order_date')
            
          );
          this.tradeForm.cptyOrderDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t('equity_bond.messages.settlement_date_before_counterparty_order_date')
          );
          this.tradeForm.cptyOrderDate = null;
        }
      }
    },
    checkTime(type) {
      if (type == "Order Time") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t('equity_bond.messages.order_time_later_execution_time')
              
            );
            this.tradeForm.orderTime = null;
          }
        }

        this.checkOrdersTime('Order Time');
      } else if ("Execution Time") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t('equity_bond.messages.execution_time_later_order_time')
              
            );
            this.tradeForm.executionTime = null;
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          if (this.tradeForm.cptyOrderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t('equity_bond.messages.execution_time_later_counterparty_order_time')
              
            );
            this.tradeForm.executionTime = null;
          }
        }
      }
    },
    checkOrdersTime(type) {
      if(type == 'Order Duration Time') {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t('equity_bond.messages.order_duration_later_order_time')
              
            );
            this.tradeForm.orderDurationTime = null;
          }
        }
      } else if(type == 'Order Time') {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t('equity_bond.messages.order_time_less_order_duration_time')
              
            );
            this.tradeForm.orderTime = null;
          }
        }
      }
    },
    checkOrdersDate(type) {
      if (type == "Order Date") {
        if(this.tradeForm.timeInForce == 'DAY') {
          this.tradeForm.orderDurationDate = this.tradeForm.orderDate
        }
        
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t('equity_bond.messages.order_date_after_order_duration_date')
            
          );
          this.tradeForm.orderDate = null;
        }
        
      } else if (type == "Order Duration Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t('equity_bond.messages.order_duration_less_order_date')
            
          );
          this.tradeForm.orderDurationDate = null;
        }
      }
    },
    changeTimeInForceType() {
      if(this.tradeForm.timeInForce == 'DAY') {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString()
        this.orderDurationDataDisabled = true;
        this.orderDurationTimeDisabled = true
        this.orderDurationInputsVisible = true
      }else if(this.tradeForm.timeInForce == 'GTC') {
        this.orderDurationInputsVisible = false
        this.tradeForm.orderDurationDate = null
        this.tradeForm.orderDurationTime = null
      } else if(this.tradeForm.timeInForce == 'GTD') {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString()
        this.orderDurationDataDisabled = false;
        this.orderDurationTimeDisabled = false;
        this.orderDurationInputsVisible = true

      }else if(this.tradeForm.timeInForce == 'FOK') {
        this.orderDurationInputsVisible = false
        this.tradeForm.orderDurationDate = null
        this.tradeForm.orderDurationTime = null

      } else if(this.tradeForm.timeInForce == 'FAK') {
        this.orderDurationInputsVisible = false
        this.tradeForm.orderDurationDate = null
        this.tradeForm.orderDurationTime = null

      }
    },
    resetTradeModal() {
      this.amendTradeModal = false;
      this.fetchedTradeData = {};
      this.oldTradeFormData = {};
      this.checkedHolidayDate = null;
      this.actionsData = [];
      this.searchedSecurityDesc =[]
      // this.searchedCounterparties = []
      // this.searchedClients = []
      this.checkVerify = false;
      ////
      this.tradeForm = {
        costCenter:null,

        orderDurationDate: moment().format("DD-MM-YYYY "),
        orderDurationTime: moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString(),
        entryType: "Order",
        buySell: "Buy",
        orderType: "Limit",
        timeInForce: "DAY",
        orderDate: moment().format("DD-MM-YYYY"),
        orderTime: moment().format("HH:mm:ss"),
        cptyOrderDate: null,
        cptyOrderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        instrumentType: "EQ",
        prop: false,
        securityDesc: null,
        quantity: null,
        clientPrice: null,
        clientPriceType: null,
        amount: null,
        counterpartyPrice: null,
        clean: false,
        accrued: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded: null,
        counterparty: null,
        cptyAccount: null,
        counterpartyTrader: null,
        scfUser: null,
        actingCapaticy: "PRINCIPAL",
        tradeDate: null,
        tradeDateName:null,
        settlementDate: null,
        settlementDateName:null,
        Tplus: null,
        executionTime: null,
        cptyExecutionTime: null,
        clientRate: null,
        clientCommission: null,
        clientAmount: null,
        counterpartyRate: null,
        tax: null,
        cptyCommission: null,
        counterpartyAmount: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        profit: null,
        tradeReportingNeeded: null,
        note: null,
        calculateCommissionFromRate1: true,
        calculateCommissionFromRate2: true,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      };

      /////
      (this.optSecurityDescriptions = []),
        // (this.optClients = []),
        (this.optClientPriceTypes = []),
        (this.optExchangeTrades = []),
        // (this.optCounterParties = []),
        (this.optCounterPartyTraders = []),
        (this.optScfUsers = []),
        (this.optOrderTakenVias = []),
        (this.optOrderGivenThroughs = []);
    },

    async tradeEnterReasonText() {
      const self = this;
      const { value: reason } = await this.$swal({
        title: self.$t('equity_bond.reason.amendment_reason'),
        text: self.$t('equity_bond.reason.please_enter_amendment_reason'),
        input: "textarea",
        inputPlaceholder: self.$t('equity_bond.reason.please_enter_amendment_reason'),
        //icon: "warning",
        showCancelButton: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t('equity_bond.reason.submit'),
        cancelButtonText: self.$t('equity_bond.reason.Cancel'),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              self.tradeForm.amendmentReason = reason;
              resolve();
            } else {
              resolve(self.$t('equity_bond.reason.you_need_write_reason'));
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      });

      if (reason) {
        return new Promise(function(resolve, reject) {
          resolve(true);
        });
      }
    },
    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    exitAmendOperationMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t('equity_bond.messages.trade_amend_cancelled'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    validReasonTextMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t('equity_bond.reason.enter_valid_reason'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    greaterThanZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `${val} must be greater than 0`,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedEnableOCOInputsMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t('equity_bond.messages.required_fill_inputs') + ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    checkJournalCreated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("equity&bond/checkTCUtiJournalCreated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t('equity_bond.messages.tc_uti_not_found'));
      }
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t('equity_bond.toast_messages.database_connection_error'),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t('equity_bond.general_messages.not_authorized_message'),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    noChangesMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t('equity_bond.messages.not_changes'),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkedTraSettlDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalCreatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkTimeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkAmendEntryTypeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    tradeUpdatedSuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t('equity_bond.toast_messages.trade_update_success'),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    formatPrice2(value, exp) {
      if (typeof exp === "undefined" || +exp === 0) return Math.round(value);

      value = +value;
      exp = +exp;

      if (isNaN(value) || !(typeof exp === "number" && exp % 1 === 0))
        return NaN;

      // Shift
      value = value.toString().split("e");
      value = Math.round(
        +(value[0] + "e" + (value[1] ? +value[1] + exp : exp))
      );

      // Shift back
      value = value.toString().split("e");
      return +(value[0] + "e" + (value[1] ? +value[1] - exp : -exp));
    },
    setTradeData() {
      const self = this;
      if(this.tradeForm.timeInForce == 'GTC' || this.tradeForm.timeInForce == 'FOK' || this.tradeForm.timeInForce == 'FAK') {
        this.tradeForm.orderDurationDate = null
        this.tradeForm.orderDurationTime = null
      }

      let setTrade = {
        costCenter : self.tradeForm.costCenter,

        instrumentType: self.tradeForm.instrumentType,
        tradeDate:
          self.tradeForm.tradeDate != null
            ? self.YMDdateFormat(self.tradeForm.tradeDate)
            : null,
        settlementDate:
          self.tradeForm.settlementDate != null
            ? self.YMDdateFormat(self.tradeForm.settlementDate)
            : null,
        orderTime:
          self.tradeForm.orderTime != null
            ? self.timeFormat(self.tradeForm.orderTime)
            : null,
        executionTime:
          self.tradeForm.executionTime != null
            ? self.timeFormat(self.tradeForm.executionTime)
            : null,
        client: self.tradeForm.client,
        account: self.tradeForm.clientAccount,
        securityDesc: self.tradeForm.securityDesc,
        isin: self.tradeForm.isin,
        buySell: self.tradeForm.buySell,
        quantity: self.tradeForm.quantity != null ? self.tradeForm.quantity : 0,
        clientAmount:
          self.tradeForm.clientAmount != null
            ? parseFloat(self.tradeForm.clientAmount)
            : null,
        counterpartyRate:
          self.tradeForm.counterpartyRate != null
            ? parseFloat(self.tradeForm.counterpartyRate)
            : null,
        comissionRate:
          self.tradeForm.clientRate != null
            ? parseFloat(self.tradeForm.clientRate)
            : null,
        counterpartyComission:
          self.tradeForm.cptyCommission != null
            ? parseFloat(self.tradeForm.cptyCommission)
            : null,
        commissionAmount:
          self.tradeForm.clientCommission != null
            ? parseFloat(self.tradeForm.clientCommission)
            : null,
        counterpartyAmount:
          self.tradeForm.counterpartyAmount != null
            ? parseFloat(self.tradeForm.counterpartyAmount)
            : null,
        counterpartyAccount: self.tradeForm.cptyAccount,
        counterpartyTrader: self.tradeForm.counterpartyTrader,
        counterpartyPrice:
          self.tradeForm.counterpartyPrice != null
            ? parseFloat(self.tradeForm.counterpartyPrice)
            : null,
        clean: self.tradeForm.Clean ? 1 : 0,
        accuredInterest:
          self.tradeForm.accrued != null
            ? parseFloat(self.tradeForm.accrued)
            : null,
        ccy: self.tradeForm.clientPriceType,
        price:
          self.tradeForm.clientPrice != null
            ? parseFloat(self.tradeForm.clientPrice)
            : null,
        amount:
          self.tradeForm.amount != null
            ? parseFloat(self.tradeForm.amount)
            : null,
        counterparty: self.tradeForm.counterparty,
        tcUser: self.tradeForm.scfUser,
        orderTakenVia: self.tradeForm.orderTakenVia,
        orderGivenThrough: self.tradeForm.orderGivenThrough,
        orderPrice: null,
        margin: self.tradeForm.tradeReportingNeeded ? 1 : 0,
        exchangeTraded: self.tradeForm.exchangeTraded,
        prop: self.tradeForm.prop ? 1 : 0,
        savedBy: self.tradeForm.savedBy ?? null,
        saveDate: self.tradeForm.saveDate!= null ? self.YMDdateFormat(self.tradeForm.saveDate) : null,
        saveTime: self.tradeForm.savetime != null ? self.HmstimeFormat(self.tradeForm.savetime) : null,
        canceled: 0,
        canceledBy: null,
        canceledDate: null,
        canceledTime: null,
        canceledReason: null,
        traderNote: self.tradeForm.note,
        securityAccount: null,
        actingCapaticy: self.tradeForm.actingCapaticy,
        counterpartOrderTime:
          self.tradeForm.cptyOrderTime != null
            ? self.timeFormat(self.tradeForm.cptyOrderTime)
            : null,
        counterpartyExecutionTime:
          self.tradeForm.cptyExecutionTime != null
            ? self.timeFormat(self.tradeForm.cptyExecutionTime)
            : null,
        tax: self.tradeForm.tax != null ? parseFloat(self.tradeForm.tax) : null,
        tcUti: self.tradeForm.uti,
        uniqueLinkId: self.tradeForm.uniqueLinkID,
        tcProfit:
          self.tradeForm.profit != null
            ? parseFloat(self.tradeForm.profit)
            : null,
        entryType: self.tradeForm.entryType,
        orderType: self.tradeForm.orderType,
        timeInForce: self.tradeForm.timeInForce,
        stopPrice:
          self.tradeForm.stopPrice != null
            ? parseFloat(self.tradeForm.stopPrice)
            : null,
        trailingAmount:
          self.tradeForm.trailingAmount != null
            ? parseFloat(self.tradeForm.tradeForm)
            : null,
        takeProfit: null,
        stopLoss:
          self.tradeForm.stopLossStop != null
            ? parseFloat(self.tradeForm.stopLossStop)
            : null,
        orderDate:
          self.tradeForm.orderDate != null
            ? self.YMDdateFormat(self.tradeForm.orderDate)
            : null,
        counterpartyOrderDate:
          self.tradeForm.cptyOrderDate != null
            ? self.YMDdateFormat(self.tradeForm.cptyOrderDate)
            : null,
        orderDurationDate:
          self.tradeForm.orderDurationDate != null
            ? self.YMDdateFormat(self.tradeForm.orderDurationDate)
            : null,
        orderDurationTime:
          self.tradeForm.orderDurationTime != null
            ? self.timeFormat(self.tradeForm.orderDurationTime)
            : null,
        enableOCO:
          self.tradeForm.enableConnectedOCOOrders != null
            ? self.tradeForm.enableConnectedOCOOrders
            : false,
        ocoType: null,
        takeProfitLimit:
          self.tradeForm.takeProfitLimit != null
            ? parseFloat(self.tradeForm.takeProfitLimit)
            : null,
        stopLossLimit:
          self.tradeForm.stopLossLimit != null
            ? parseFloat(self.tradeForm.stopLossLimit)
            : null,
        ocoLink: null,
        orderQuantity: null,
        future4: null,
        future5: null,
        future6: null,
        future7: null,
        future8: null,
        future9: null,
        future10: null,
        future11: null,
        future12: null,
        future13: null,
        future14: null,
        future15: null,
        future16: null,
        ocoLink: self.tradeForm.ocoLink ?? null,
        ocoType: self.tradeForm.ocoType ?? null,
        amendedBy: self.user.name,
        amendmentDate: moment().format("YYYY-MM-DD"),
        amendmentReason: self.tradeForm.amendmentReason,
        amendmentTime: moment().format("HH:mm:ss"),
        cancelDate: self.tradeForm.cancelDate ?? null,
        cancelReason: self.tradeForm.cancelReason ?? null,
        cancelTime: self.tradeForm.cancelTime ?? null,
        cancelled: self.tradeForm.cancelled ?? "0",
        cancelledBy: self.tradeForm.cancelledBy ?? null,
        ID: self.tradeForm.tradeRef,
      };

      return setTrade;
    },
  },
  watch: {
    amendTradeModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeEquityBondTCUsers();
          //this.getArrangeEquityBondCurrencies();
          this.getArrangeEquityBondOrderTakenGivenThrough();

          //   this.tradeForm.scfUser = this.user.name;
          //   this.tradeForm.orderDurationDate = moment().format("DD-MM-YYYY ");
          //   this.tradeForm.orderDurationTime = moment()
          //     .endOf("day")
          //     .format("HH:mm:ss")
          //     .toString();

          //   this.tradeForm.entryType = "Order";
          //   this.tradeForm.buySell = "Buy";
          //   this.tradeForm.orderType = "Limit";
          //   this.tradeForm.timeInForce = "DAY";
          //   this.tradeForm.orderDate = moment().format("DD-MM-YYYY");
          //   this.tradeForm.orderTime = moment().format("HH:mm:ss");
          //   this.tradeForm.actingCapaticy = "PRINCIPAL";
          //   this.tradeForm.instrumentType = "EQ";
        }
      },
    },
    "tradeForm.instrumentType": {
      handler: function(val, before) {
        if (before != null) {
          this.convertNumberInputFormat()
          this.calculateClientFaceValue();
          this.arrangeTaxRate();
          this.calculateClientComission();
          this.calculateCounterpartyComission();
          this.calculateClientAmount();
          this.calculateCounterpartyAmount();
          this.calculateTCProfit();

          if(val == 'FI'){
          this.tradeForm.securityDesc = null
          this.tradeForm.isin = null;
          this.tradeForm.clientPriceType = null;
          this.tradeForm.exchangeTraded = null;
          this.tradeForm.tax = null
          this.tradeForm.actingCapaticy = "PRINCIPAL"
          this.tradeForm.cptyExecutionTime = moment().format("HH:mm:ss");
        } else {
          this.tradeForm.securityDesc = null
          this.tradeForm.isin = null;
          this.tradeForm.clientPriceType = null;
          this.tradeForm.exchangeTraded = null;
        }
        }
      },
      deep: true,
    },
    "tradeForm.buySell": {
      handler: function(val, before) {
        if (before != null) {
          this.convertNumberInputFormat()
          this.calculateClientFaceValue();
          this.arrangeTaxRate();
          this.calculateClientComission();
          this.calculateCounterpartyComission();
          this.calculateClientAmount();
          this.calculateCounterpartyAmount();
          this.calculateTCProfit();
          this.checkEnableOCOInputs()
        }
        
      },
      deep: true,
    },


    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = {
            costCenter:val.CostCenter,
            orderDurationDate:
              val.OrderDurationDate != ""
                ? this.dateFormat(val.OrderDurationDate)
                : null,
            orderDurationTime:
              val.OrderDurationTime != ""
                ? val.OrderDurationTime
                : null,
            entryType: val.EntryType ?? null,
            buySell: val.BuySell ?? null,
            orderType: val.OrderType ?? null,
            timeInForce: val.TimeinForce ?? null,
            orderDate:
              val.OrderDate != "" ? this.dateFormat(val.OrderDate) : null,
            orderTime:
              val.OrderTime != "" ? val.OrderTime : null,
            cptyOrderDate:
              val.CounterpartyOrderDate != ""
                ? this.dateFormat(val.CounterpartyOrderDate)
                : null,
            cptyOrderTime:
              val.CptyOrderTime != ""
                ? val.CptyOrderTime
                : null,
            enableConnectedOCOOrders: val.EnableOCO == "1" ? true : false,
            stopPrice: val.StopPrice != "" ? parseFloat(val.StopPrice) : null,
            trailingAmount:
              val.TrailingAmount != "" ? parseFloat(val.TrailingAmount) : null,
            takeProfitLimit:
              val.TakeProfitLimit != ""
                ? parseFloat(val.TakeProfitLimit)
                : null,
            stopLossStop: val.StopLoss != "" ? parseFloat(val.StopLoss) : null,
            stopLossLimit:
              val.StopLossLimit != "" ? parseFloat(val.StopLossLimit) : null,
            instrumentType: val.InstrumentType ?? null,
            securityDesc: val.SecurityDescription ?? null,
            quantity: val.Quantity ?? null,
            clientPrice: val.Price != "" ? parseFloat(val.Price) : null,
            clientPriceType: val.Ccy ?? null,
            amount: val.Amount != "" ? parseFloat(val.Amount) : null,
            counterpartyPrice:
              val.CounterpartyPrice != ""
                ? parseFloat(val.CounterpartyPrice)
                : null,
            clean: val.Clean == "0" ? false : true,
            accrued: val.AccuredInterest != "" ? val.AccuredInterest : null,
            client: val.Client ?? null,
            clientAccount: val.Account ?? null,
            isin: val.Isin ?? null,
            exchangeTraded: val.ExchangeTraded ?? null,
            counterparty: val.Counterparty ?? null,
            cptyAccount: val.CounterpartyAccount ?? null,
            counterpartyTrader: val.CounterpartyTrader ?? null,
            scfUser: val.TcUser ?? null,
            actingCapaticy: val.ActingCapacity ?? null,
            tradeReportingNeeded: val.margin == "0" ? false : true,
            tradeDate:
              val.TradeDate != "" ? this.dateFormat(val.TradeDate) : null,
            settlementDate:
              val.SettlementDate != ""
                ? this.dateFormat(val.SettlementDate)
                : null,
            executionTime:
              val.ExecutionTime != ""
                ? val.ExecutionTime
                : null,
            cptyExecutionTime:
              val.CptyExecutionTime != ""
                ? val.CptyExecutionTime
                : null,
            clientRate:
              val.CommissionRate != "" ? parseFloat(val.CommissionRate) : null,
            clientCommission:
              val.CommissionAmount != ""
                ? parseFloat(val.CommissionAmount)
                : null,
            clientAmount:
              val.ClientAmount != "" ? parseFloat(val.ClientAmount) : null,
            counterpartyRate:
              val.counterpartyRate != ""
                ? parseFloat(val.CounterpartyRate)
                : null,
            tax: val.Tax != "" ? val.Tax : null,
            cptyCommission:
              val.CounterpartyCommission != ""
                ? parseFloat(val.CounterpartyCommission)
                : null,
            counterpartyAmount:
              val.CounterpartyAmount != ""
                ? parseFloat(val.CounterpartyAmount)
                : null,
            orderTakenVia: val.OrderTakenVia ?? null,
            orderGivenThrough: val.OrderGivenThrough ?? null,
            profit: val.TcProfit != "" ? parseFloat(val.TcProfit) : null,
            tradeReportingNeeded: val.TradeReportingNeeded == 1 ? true : false,
            note: val.TraderNote ?? null,
            calculateCommissionFromRate1:
              val.CalculateCommissionFromRate1 == 1 ? true : false,
            calculateCommissionFromRate2:
              val.CalculateCommissionFromRate2 == 1 ? true : false,
            uti: val.TcUti ?? null,
            uniqueLinkID: val.UniqueLinkId ?? null,
            tradeRef: val.id,
            tradeStart: null,
            tradeEnd: null,
            settleStart: null,
            settleEnd: null,
            ocoLink: val.OCOLink ?? null,
            ocoType: val.OCOType ?? null,
            saveDate: val.SaveDate != "" ? val.SaveDate : null,
            savetime: val.SaveTime != "" ? val.SaveTime : null,
            savedBy: val.SavedBy != "" ? val.SavedBy : null,
            amanededBy: val.AmendedBy != "" ? val.AmendedBy : null,
            amendmentDate: val.AmendmentDate != "" ? val.AmendmentDate : null,
            amendmentReason:
              val.AmendmentReason != "" ? val.AmendmentReason : null,
            amendmentTime: val.AmendmentTime != "" ? val.AmendmentTime : null,
            cancelDate: val.CancelDate != "" ? val.CancelDate : null,
            cancelReason: val.CancelReason != "" ? val.CancelReason : null,
            cancelTime: val.CancelTime != "" ? val.CancelTime : null,
            cancelled: val.Cancelled != "0" ? val.CancelTime : "1",
            cancelledBy: val.CancelledBy != "" ? val.CancelledBy : null,
          };

          

          if (isNaN(this.tradeForm.counterpartyPrice)) {
            this.tradeForm.counterpartyPrice = null;
          }

          this.tradeForm.orderDurationDate = this.tradeForm.orderDate
          this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString()

          this.changeTimeInForceType()

          //set trade date name
          let tradeDate = new Date(this.MDYdateFormat(this.tradeForm.tradeDate));
        
          this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];
          //set settle date name
          let settleDate = new Date(this.MDYdateFormat(this.tradeForm.settlementDate));
        
          this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];

          if(this.tradeForm.accrued != null) {
            this.tradeForm.clean = true
          }

          this.oldTradeFormData = JSON.parse(JSON.stringify(this.tradeForm));

          this.arrangeInputFormat()
        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.eq_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #191970;
}

.fi_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #cd853f;
}

.settlementDateName .input-group-append .input-group-text {
  color: red;
}

.tradeDateName .input-group-append .input-group-text {
  color: red;
}

.animate__animated {
  animation-fill-mode: none;
}

.input-dropdown .btn {
  padding: 0;
}

.swall2-overlay {
  z-index: 100005;
}
.swal2-modal {
  z-index: 99999;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
