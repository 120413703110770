<template>
  <div>
    {{ /*search trade modal*/ }}
    <b-modal
      id="searchTradeModal"
      scrollable
      v-model="searchForTradesModal"
      :title="$t('equity_bond.titles.search_for_trade')"
      @hidden="resetSearchForTradesModal"
      size="xl"
      :ok-title="$t('equity_bond.modals.search_trade_modal_ok')"
      :cancel-title="$t('equity_bond.modals.modal_cancel')"
      @ok="searchForTrades"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="searchTradeModal"
    >
      <b-overlay :show="searchTradesloading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="searchValidation">
            <b-form>
              <!-- /*******************************Order Details***************************************************************** */ -->
              <b-row class="mt-1">
                <b-card
                  :title="$t('equity_bond.titles.order_details')"
                  style="width: 100% !important;"
                >
                  <hr />
                  <b-row>
                    <div
                      class="d-flex justify-content-end"
                      style="width: 100%;"
                    >
                      <!-- order date-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.order_date')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_date')"
                          >
                            <flat-pickr
                              :placeholder="
                                $t('equity_bond.trade_form.order_date')
                              "
                              v-model="orderDateRange"
                              onClose="testClose"
                              class="form-control"
                              :config="orderDateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="checkOrdersDate('Order Date')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order time-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.order_time')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_time')"
                          >
                            <cleave
                              id="orderTime"
                              v-model="tradeForm.orderTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('equity_bond.trade_form.order_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="checkTime('Order Time')"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration date -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.order_duration_date')
                          "
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.order_duration_date')
                            "
                          >
                            <flat-pickr
                              :placeholder="
                                $t('equity_bond.trade_form.order_duration_date')
                              "
                              v-model="durationDateRange"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              :state="errors.length > 0 ? false : null"
                              :disabled="orderDurationDataDisabled"
                              @on-change="
                                checkOrdersDate('Order Duration Date')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration time -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.order_duration_time')
                          "
                          v-if="orderDurationInputsVisible"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.order_duration_time')
                            "
                          >
                            <cleave
                              id="orderDurationTime"
                              v-model="tradeForm.orderDurationTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="
                                $t('equity_bond.trade_form.order_duration_time')
                              "
                              :state="errors.length > 0 ? false : null"
                              @blur.native="
                                checkOrdersTime('Order Duration Time')
                              "
                              :disabled="orderDurationTimeDisabled"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                    <!-- entry type -->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.entry_type')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.entry_type')"
                        >
                          <v-select
                            v-model="tradeForm.entryType"
                            :options="optEntryTypes"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_entry_type')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- buy/sell -->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.buy_sell')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.buy_sell')"
                        >
                          <v-select
                            v-model="tradeForm.buySell"
                            :options="optbuySells"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_buy_sell')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- order type-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.order_type')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.order_type')"
                        >
                          <v-select
                            v-model="tradeForm.orderType"
                            :options="optOrderTypes"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_order_type')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- time in force-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.time_in_force')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.time_in_force')"
                        >
                          <v-select
                            v-model="tradeForm.timeInForce"
                            :options="optTimeInForces"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_time_in_force')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            @option:selected="changeTimeInForceType"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- cpty order date-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cpty_order_date')"
                        v-if="tradeForm.instrumentType == 'FI'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_order_date')"
                        >
                          <flat-pickr
                            :placeholder="
                              $t('equity_bond.trade_form.cpty_order_date')
                            "
                            v-model="cptyOrderDateRange"
                            onClose="testClose"
                            class="form-control"
                            :config="dateConfig"
                            style="border:1px solid #CD853F"
                            :state="errors.length > 0 ? false : null"
                            @on-change="
                              checkTraSettlCptyDate('Cpty Order Date')
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                        
                    <!-- cpty order time-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cpty_order_time')"
                        v-if="tradeForm.instrumentType == 'FI'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_order_time')"
                        >
                          <cleave
                            id="orderTime"
                            v-model="tradeForm.cptyOrderTime"
                            class="form-control"
                            :raw="false"
                            :options="cleaveOptions.time"
                            style="border:1px solid #CD853F"
                            :placeholder="
                              $t('equity_bond.trade_form.cpty_order_time')
                            "
                            :state="errors.length > 0 ? false : null"
                            @blur.native="checkTime('Execution Time')"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>

                 
                    </b-col>
                    <!-- Stop Price -->


                      <b-col
                      cols="3"
                      xl="3"
                      lg="3"
                      md="3"
                      sm="12"
                      v-if="
                        tradeForm.orderType == 'Stop' ||
                          tradeForm.orderType == 'Trailing Stop'
                      "
                    >
                      <b-form-group
                        :label="$t('equity_bond.trade_form.stop_price_start')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.stop_price_start')"
                          :rules="'min_value:0'
                          "
                        >
                          <cleave
                            id="stopPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model.number="tradeForm.stopPrice"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.stop_price_start')
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>


                    <b-col
                      cols="3"
                      xl="3"
                      lg="3"
                      md="3"
                      sm="12"
                      v-if="
                        tradeForm.orderType == 'Stop' ||
                          tradeForm.orderType == 'Trailing Stop'
                      "
                    >
                      <b-form-group
                        :label="$t('equity_bond.trade_form.stop_price_end')"
                      >
                    
                          <cleave
                            id="stopPrice"
                            v-model.number="tradeForm.stopPriceEnd"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.stop_price_end')
                            "
                          />
             
                      </b-form-group>
                    </b-col>




                        
                    <!-- Trailing Amount -->


                    <b-col v-if="tradeForm.orderType == 'Trailing Stop'" cols="3" xl="3" lg="3" md="3" sm="12">
                      </b-col>
                      <b-col v-if="tradeForm.orderType == 'Trailing Stop'" cols="3" xl="3" lg="3" md="3" sm="12">
                      </b-col>



  <b-col
                      cols="3"
                      xl="3"
                      lg="3"
                      md="3"
                      sm="12"
                      v-if="tradeForm.orderType == 'Trailing Stop'"
                    >
                      <b-form-group
                        :label="$t('equity_bond.trade_form.trailing_amount_start')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.trailing_amount_start')"
                          :rules="
                            tradeForm.orderType == 'Trailing Stop'
                              ? 'min_value:0'
                              : ''
                          "
                        >
                          <b-form-input
                            id="trailingAmount"
                            v-model.number="tradeForm.trailingAmount"
                            name="trailingAmount"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('equity_bond.trade_form.trailing_amount_start')
                            "
                            autofocus
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="3"
                      xl="3"
                      lg="3"
                      md="3"
                      sm="12"
                      v-if="tradeForm.orderType == 'Trailing Stop'"
                    >
                      <b-form-group
                        :label="$t('equity_bond.trade_form.trailing_amount_end')"
                      >
                    
                          <b-form-input
                            id="trailingAmount"
                            v-model.number="tradeForm.trailingAmountEnd"
                            name="trailingAmount"
                            type="number"
                            :placeholder="
                              $t('equity_bond.trade_form.trailing_amount_end')
                            "
                            autofocus
                          />
                    
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <hr />
                    </b-col>
                    <div style="display:flex; width: 100%;">
                      <!-- enable connected OCO orders-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="enable OCO orders"
                          >
                            <!-- <b-form-checkbox
                              v-model="tradeForm.enableConnectedOCOOrders"
                            >
                              {{ $t("equity_bond.trade_form.enable_oco") }}
                            </b-form-checkbox> -->

                              <v-select 
                              label="title"
                              v-model="tradeForm.enableConnectedOCOOrders"
                              :options="optEnableOCOs" 
                              :clearable="false"
                              :reduce="(val) => val"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Take Profit Limit -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.take_profit_limit_start')
                          "
                          v-if="tradeForm.enableConnectedOCOOrders == 'Enable Connected OCO Orders'"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.take_profit_limit_start')
                            "
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="takeProfitLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model.number="tradeForm.takeProfitLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.take_profit_limit_start')
                              "
                              @blur.native="
                                checkEnableOCOInputs('takeProfitLimit')
                              "
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Stop -->

                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.stop_loss_stop_start')"
                          v-if="tradeForm.enableConnectedOCOOrders == 'Enable Connected OCO Orders'"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.stop_loss_stop_start')"
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossStop"
                              :state="errors.length > 0 ? false : null"
                              v-model.number="tradeForm.stopLossStop"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.stop_loss_stop_start')
                              "
                              @blur.native="
                                checkEnableOCOInputs('stopLossStop')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      


                      <!-- Stop Loss Limit -->

                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.stop_loss_limit_start')"
                          v-if="tradeForm.enableConnectedOCOOrders == 'Enable Connected OCO Orders'"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.stop_loss_limit_start')"
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'min_value:0'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model.number="tradeForm.stopLossLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.stop_loss_limit_start')
                              "
                              @blur.native="
                                checkEnableOCOInputs('stopLossLimit')
                              "
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    
                    </div>

                    <div style="display:flex; width: 100%;">
                      <!-- enable connected OCO orders-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      </b-col>

                      <!-- Take Profit Limit -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.take_profit_limit_end')
                          "
                          v-if="tradeForm.enableConnectedOCOOrders == 'Enable Connected OCO Orders'"
                        >
                          
                            <cleave
                              id="takeProfitLimit"
                              v-model.number="tradeForm.takeProfitLimitEnd"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.take_profit_limit_end')
                              "
                              @blur.native="
                                checkEnableOCOInputs('takeProfitLimit')
                              "
                            />

                   
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Stop -->

                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.stop_loss_stop_end')"
                          v-if="tradeForm.enableConnectedOCOOrders == 'Enable Connected OCO Orders'"
                        >
                      
                            <cleave
                              id="stopLossStop"
                              v-model.number="tradeForm.stopLossStopEnd"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.stop_loss_stop_end')
                              "
                              @blur.native="
                                checkEnableOCOInputs('stopLossStop')
                              "
                            />
                         
                        </b-form-group>
                      </b-col>


                      <!-- Stop Loss Limit -->



                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.stop_loss_limit_end')"
                          v-if="tradeForm.enableConnectedOCOOrders == 'Enable Connected OCO Orders'"
                        >
                    
                            <cleave
                              id="stopLossLimit"
                              v-model.number="tradeForm.stopLossLimitEnd"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.stop_loss_limit_end')
                              "
                              @blur.native="
                                checkEnableOCOInputs('stopLossLimit')
                              "
                            />
                         
                        </b-form-group>
                      </b-col>



                      
                    
                    </div>
                  </b-row>
                </b-card>
              </b-row>
              <b-row>
                <!-- /******************************* Security Details***************************************************************** */ -->
                <b-col cols="4" sm="12" md="4" lg="4" xl="4" class="p-0">
                  <b-card
                    :title="$t('equity_bond.titles.security_details')"
                    style="width: 100% !important;"
                  >
                    <!-- instrument type-->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.instrument_type')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.instrument_type')"
                        >
                          <v-select
                            v-model="tradeForm.instrumentType"
                            :options="optInstrumentTypes"
                            :class="
                              tradeForm.instrumentType == 'EQ'
                                ? 'eq_color'
                                : 'fi_color'
                            "
                            :clearable="false"
                            :placeholder="
                              $t(
                                'equity_bond.trade_form.select_instrument_type'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>


                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cost_center')"
                      >
                    

                      <v-select
                      v-model="tradeForm.costCenter"
                      :options="optCostCenters"
                 
                      :clearable="false"
                      :placeholder="$t('equity_bond.trade_form.select_cost_center')"
                      :reduce="(val) => val.CostCenter"
                      label = 'CostCenter'

                    />
              
                  </b-form-group>
                    </b-col>






                    <!-- security desc-->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.security_desc')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.security_desc')"
                        >
                          <v-select
                            v-model="tradeForm.securityDesc"
                            :options="optSecurityDescriptions"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_security_desc')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :loading="loadingSecurityDesc"
                            @search="
                              (search, loading) => {
                                loading(false);
                                searchSecuritydesc(search);
                              }
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- quantity -->

                    <div class="d-flex">

                      <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.quantity_start')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.quantity_start')"
                        >
                          <b-form-input
                            id="quantity"
                            v-model.number="tradeForm.quantity"
                            name="quantity"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.quantity_start')"
                            autofocus
                            @change="calculateAmount"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>


                    <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.quantity_end')"
                      >
              
                          <b-form-input
                            id="quantity"
                            v-model.number="tradeForm.quantityEnd"
                            name="quantity"
                            type="number"
                            :placeholder="$t('equity_bond.trade_form.quantity_end')"
                            autofocus
                            @change="calculateAmount"
                          />
                     
                      </b-form-group>
                    </b-col>



                    </div>
                 
                    <!-- client price -->
                    <div class="d-flex">

                      <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.price_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.price_type')"
                          >
                            <v-select
                              v-model="tradeForm.clientPriceType"
                              :options="optClientPriceTypes"
                              :clearable="false"
                              :placeholder="
                                $t('equity_bond.trade_form.price_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      </div>
                      <div class="d-flex" >

                      <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                        
                          :label="$t('equity_bond.trade_form.client_price_start')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client_price_start')"
                            rules="min_value:0"
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model.number="tradeForm.clientPrice"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.client_price_start')
                              "
                              @blur.native="calculateAmount"
                            />
                            
                

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>

                       
                       

                        </b-form-group>
                      </b-col>

                      <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                        <b-form-group
                        :label="$t('equity_bond.trade_form.client_price_end')"

                        >
                  
                
                            <cleave

                              id="clientPrice"
                              v-model.number="tradeForm.clientPriceEnd"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.client_price_end')
                              "
                              @blur.native="calculateAmount"
                            />


                          
                       
                       

                        </b-form-group>
                      </b-col>
                     
                    </div>
                    <!-- amount -->
                    <div class="d-flex">

                      <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.amount_start')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.amount_start')"
                        >
                          <cleave
                            id="amount"
                            :state="errors.length > 0 ? false : null"
                            v-model.number="tradeForm.amount"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="$t('equity_bond.trade_form.amount_start')"
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.amount_end')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.amount_end')"
                        >
                          <cleave
                            id="amount"
                            :state="errors.length > 0 ? false : null"
                            v-model.number="tradeForm.amountEnd"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="$t('equity_bond.trade_form.amount_end')"
                            :disabled="true"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    </div>
                 

                    <!-- counterparty price -->

                    <div class="d-flex"> 
                      <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.counterparty_price_start')"
                        v-if="tradeForm.instrumentType == 'FI'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('equity_bond.trade_form.counterparty_price_start')
                          "
                        >
                          <cleave
                            id="counterpartyPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model.number="tradeForm.counterpartyPrice"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.counterparty_price_start')
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <b-col cols="6" xl="6" lg="6" md="6" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.counterparty_price_end')"
                        v-if="tradeForm.instrumentType == 'FI'"
                      >
                   
                          <cleave
                            id="counterpartyPrice"
                            v-model.number="tradeForm.counterpartyPriceEnd"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="
                              $t('equity_bond.trade_form.counterparty_price_end')
                            "
                          />
                 
                      </b-form-group>
                    </b-col>


                    </div>
                    

                    <div
                      class="d-flex"
                      style="width: 100% !important;"
                      v-if="tradeForm.instrumentType == 'FI'"
                    >
                      <!-- Clean-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="clean"
                          >
                            <b-form-checkbox v-model="tradeForm.clean">
                              {{ $t("equity_bond.trade_form.clean") }}
                            </b-form-checkbox>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- accrued -->
                      <b-col cols="9" xl="9" lg="9" md="9" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.accrued')"
                          v-if="tradeForm.clean"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.accrued')"
                          >
                            <b-form-input
                              id="accrued"
                              v-model.number="tradeForm.accrued"
                              name="accrued"
                              type="number"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="
                                $t('equity_bond.trade_form.accrued')
                              "
                              autofocus
                              style="border:1px solid #CD853F"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <!-- client -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.client')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.client')"
                        >
                          <v-select
                            v-model="tradeForm.client"
                            :options="optClients"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_client')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :loading="loadingClient"
                            @option:selecting="selectedClient"
                        
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- client account -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.client_account')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.client_account')"
                        >
                          <b-form-input
                            id="clientAccount"
                            v-model="tradeForm.clientAccount"
                            name="clientAccount"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('equity_bond.trade_form.client_account')
                            "
                            autofocus
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- ISIN -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.isin')">
                        <validation-provider #default="{ errors }" name="ISIN">
                          <b-form-input
                            id="isin"
                            v-model="tradeForm.isin"
                            :name="$t('equity_bond.trade_form.isin')"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.isin')"
                            autofocus
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- exchange traded -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.exchange_traded')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.exchange_traded')"
                        >
                          <v-select
                            v-model="tradeForm.exchangeTraded"
                            :options="optExchangeTrades"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'equity_bond.trade_form.select_exchange_traded'
                              )
                            "
                            :reduce="(val) => val"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- counter party -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.counterparty')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.counterparty')"
                        >
                          <v-select
                            v-model="tradeForm.counterparty"
                            :options="optCounterParties"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_counterparty')
                            "
                            :reduce="(val) => val"
                            :loading="loadingCounterparty"
                            @option:selecting="selectedCounterparty"
                   
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty account -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cpty_account')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_account')"
                        >
                          <b-form-input
                            id="isin"
                            v-model="tradeForm.cptyAccount"
                            name="cpty account"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('equity_bond.trade_form.cpty_account')
                            "
                            autofocus
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- counter party trader -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="
                          $t('equity_bond.trade_form.counterparty_trader')
                        "
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="
                            $t('equity_bond.trade_form.counterparty_trader')
                          "
                        >
                          <v-select
                            v-model="tradeForm.counterpartyTrader"
                            :options="optCounterPartyTraders"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'equity_bond.trade_form.select_counterparty_trader'
                              )
                            "
                            :reduce="(val) => val"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- sfc user -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.sfc_user')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.sfc_user')"
                        >
                          <v-select
                            v-model="tradeForm.scfUser"
                            :options="optScfUsers"
                            :clearable="false"
                            :placeholder="
                              $t('equity_bond.trade_form.select_sfc_user')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- acting capacity -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.acting_capacity')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.acting_capacity')"
                        >
                          <v-select
                            v-model="tradeForm.actingCapaticy"
                            :options="optActingCapacities"
                            :clearable="false"
                            :placeholder="
                              $t(
                                'equity_bond.trade_form.select_acting_capacity'
                              )
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-card>
                </b-col>

                <b-col cols="8" sm="12" md="8" lg="8" xl="8" class="p-0">
                  <!-- /******************************* Trade Date & Time***************************************************************** */ -->
                  <b-col cols="12" sm="12" mf="12" lg="12" xl="12" class="pr-0">
                    <b-card
                      :title="$t('equity_bond.titles.trade_date_time')"
                      style="width: 100% !important;"
                    >
                      <div class="d-flex">
                        <!-- Trade Date -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.trade_date')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.trade_date')"
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('equity_bond.trade_form.trade_date')
                                "
                                v-model="tradeDateRange"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="checkTraSettlCptyDate('Trade Date')"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Settlement Date -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.settlement_date')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.settlement_date')
                              "
                            >
                              <flat-pickr
                                :placeholder="
                                  $t('equity_bond.trade_form.settlement_date')
                                "
                                v-model="settleDateRange"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                @on-change="
                                  checkTraSettlCptyDate('Settlement Date')
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- execution time -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.execution_time')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.execution_time')
                              "
                            >
                              <cleave
                                id="executionTime"
                                v-model="tradeForm.executionTime"
                                class="form-control"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="
                                  $t('equity_bond.trade_form.execution_time')
                                "
                                :state="errors.length > 0 ? false : null"
                                @blur.native="checkTime('Execution Time')"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <div class="d-flex" style="width: 100%;">
                        <!-- cpty execution time -->
                        <b-col
                          cols="4"
                          xl="4"
                          lg="4"
                          md="4"
                          sm="12"
                          v-if="tradeForm.instrumentType == 'FI'"
                        >
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.cpty_execution_time')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.cpty_execution_time')
                              "
                            >
                              <cleave
                                id="cptyExecutionTime"
                                v-model="tradeForm.cptyExecutionTime"
                                class="form-control"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.cpty_execution_time'
                                  )
                                "
                                :state="errors.length > 0 ? false : null"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                    </b-card>
                  </b-col>

                  <!-- /******************************* Trade Details***************************************************************** */ -->

                  <b-col cols="12" sm="12" mf="12" lg="12" xl="12" class="pr-0">
                    <b-card
                      :title="$t('equity_bond.titles.trade_details')"
                      style="width: 100% !important;"
                    >
                      <div class="d-flex">
                        <!-- Client Rate -->
                        <b-col cols="5" xl="5" lg="5" md="5" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.client_rate')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client_rate')"
                              :rules="
                                tradeForm.calculateCommissionFromRate1
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <b-input-group append="Bps">
                                <b-input-group-prepend is-text>
                                  <b-form-checkbox
                                    plain
                                    name="checkbox-input"
                                    v-model="
                                      tradeForm.calculateCommissionFromRate1
                                    "
                                    v-b-tooltip.hover.top
                                    :title="
                                      $t(
                                        'equity_bond.trade_form.client_rate_tooltip'
                                      )
                                    "
                                  >
                                  </b-form-checkbox>
                                </b-input-group-prepend>

                                <cleave
                                  id="clientRate"
                                  :state="errors.length > 0 ? false : null"
                                  v-model.number="tradeForm.clientRate"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.client_rate')
                                  "
                                  :disabled="
                                    !tradeForm.calculateCommissionFromRate1
                                  "
                                  @blur.native="calculateClientComission"
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Client Commission -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.client_comission_start')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.client_comission_start')
                              "
                              :rules="
                                !tradeForm.calculateCommissionFromRate1
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="clientCommission"
                                :state="errors.length > 0 ? false : null"
                                v-model.number="tradeForm.clientCommission"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.client_comission_start')
                                "
                                :disabled="
                                  tradeForm.calculateCommissionFromRate1
                                "
                                @blur.native="calculateClientRate"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Client amount -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.client_amount_start')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client_amount_start')"
                              rules="min_value:0"
                            >
                              <cleave
                                id="clientAmmount"
                                :state="errors.length > 0 ? false : null"
                                v-model.number="tradeForm.clientAmount"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.client_amount_start')
                                "
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>






                      <div class="d-flex">
                        <!-- Client Rate -->
                        <b-col cols="5" xl="5" lg="5" md="5" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.client_rate_end')"
                          >
                     
                              <b-input-group append="Bps">
                                <b-input-group-prepend is-text>
                                  <b-form-checkbox
                                    plain
                                    name="checkbox-input"
                                    v-model="
                                      tradeForm.calculateCommissionFromRate1End
                                    "
                                    v-b-tooltip.hover.top
                                    :title="
                                      $t(
                                        'equity_bond.trade_form.client_rate_tooltip'
                                      )
                                    "
                                  >
                                  </b-form-checkbox>
                                </b-input-group-prepend>

                                <cleave
                                  id="clientRate"
                                  v-model.number="tradeForm.clientRateEnd"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.client_rate_end')
                                  "
                                  :disabled="
                                    !tradeForm.calculateCommissionFromRate1End
                                  "
                                  @blur.native="calculateClientComission"
                                />
                              </b-input-group>

                             
                          </b-form-group>
                        </b-col>
                        <!-- Client Commission -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.client_comission_end')
                            "
                          >
                           
                              <cleave
                                id="clientCommission"
                                v-model.number="tradeForm.clientCommissionEnd"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.client_comission_end')
                                "
                                :disabled="
                                  tradeForm.calculateCommissionFromRate1End
                                "
                                @blur.native="calculateClientRate"
                              />
                            
                          </b-form-group>
                        </b-col>
                        <!-- Client amount -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.client_amount_end')"
                          >
                           
                              <cleave
                                id="clientAmmount"
                                v-model.number="tradeForm.clientAmountEnd"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.client_amount_end')
                                "
                                :disabled="true"
                              />
                            
                          </b-form-group>
                        </b-col>
                      </div>


                      <div class="d-flex">
                        <!-- Counterparty Rate -->
                        <b-col cols="5" xl="5" lg="5" md="5" sm="12">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.counterparty_rate_start')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.counterparty_rate_start')
                              "
                              :rules="
                                tradeForm.calculateCommissionFromRate2
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <b-input-group append="Bps">
                                <b-input-group-prepend is-text>
                                  <b-form-checkbox
                                    plain
                                    name="checkbox-input"
                                    v-model="
                                      tradeForm.calculateCommissionFromRate2
                                    "
                                    v-b-tooltip.hover.top
                                    :title="
                                      $t(
                                        'equity_bond.trade_form.counterparty_rate_tooltip'
                                      )
                                    "
                                  />
                                </b-input-group-prepend>

                                <cleave
                                  id="counterpartyRate"
                                  :state="errors.length > 0 ? false : null"
                                  v-model.number="tradeForm.counterpartyRate"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="
                                    $t(
                                      'equity_bond.trade_form.counterparty_rate_start'
                                    )
                                  "
                                  :disabled="
                                    !tradeForm.calculateCommissionFromRate2
                                  "
                                  @blur.native="calculateCounterpartyComission"
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- cpty comission -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.cpty_comission_start')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.cpty_comission_start')
                              "
                              :rules="
                                !tradeForm.calculateCommissionFromRate2
                                  ? 'min_value:0'
                                  : ''
                              "
                            >
                              <cleave
                                id="cptyComission"
                                :state="errors.length > 0 ? false : null"
                                v-model.number="tradeForm.cptyCommission"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.cpty_comission_start')
                                "
                                :disabled="
                                  tradeForm.calculateCommissionFromRate2
                                "
                                @blur.native="calculateCounterpartyRate"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- counterparty amount -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.counterparty_amount_start')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.counterparty_amount_start')
                              "
                              rules="min_value:0"
                            >
                              <cleave
                                id="counterpartyAmount"
                                :state="errors.length > 0 ? false : null"
                                v-model.number="tradeForm.counterpartyAmount"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.counterparty_amount_start'
                                  )
                                "
                                :disabled="true"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>



                      <div class="d-flex">
                        <!-- Counterparty Rate -->
                        <b-col cols="5" xl="5" lg="5" md="5" sm="12">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.counterparty_rate_end')
                            "
                          >
                           
                              <b-input-group append="Bps">
                                <b-input-group-prepend is-text>
                                  <b-form-checkbox
                                    plain
                                    name="checkbox-input"
                                    v-model="
                                      tradeForm.calculateCommissionFromRate2End
                                    "
                                    v-b-tooltip.hover.top
                                    :title="
                                      $t(
                                        'equity_bond.trade_form.counterparty_rate_tooltip'
                                      )
                                    "
                                  />
                                </b-input-group-prepend>

                                <cleave
                                  id="counterpartyRate"
                                  v-model.number="tradeForm.counterpartyRateEnd"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="
                                    $t(
                                      'equity_bond.trade_form.counterparty_rate_end'
                                    )
                                  "
                                  :disabled="
                                    !tradeForm.calculateCommissionFromRate2End
                                  "
                                  @blur.native="calculateCounterpartyComission"
                                />
                              </b-input-group>

                      
                          </b-form-group>
                        </b-col>

                        <!-- cpty comission -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.cpty_comission_end')"
                          >
                          
                              <cleave
                                id="cptyComission"
                                v-model.number="tradeForm.cptyCommissionEnd"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.cpty_comission_end')
                                "
                                :disabled="
                                  tradeForm.calculateCommissionFromRate2End
                                "
                                @blur.native="calculateCounterpartyRate"
                              />
                             
                          </b-form-group>
                        </b-col>
                        <!-- counterparty amount -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.counterparty_amount_end')
                            "
                          >
                          
                              <cleave
                                id="counterpartyAmount"
                                v-model.number="tradeForm.counterpartyAmountEnd"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.counterparty_amount_end'
                                  )
                                "
                                :disabled="true"
                              />
                      
                          </b-form-group>
                        </b-col>
                      </div>


                      <div class="d-flex">
                        <!-- profit -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.profit_start')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.profit_start')"
                            >
                              <cleave
                                id="profit"
                                :state="errors.length > 0 ? false : null"
                                v-model.number="tradeForm.profit"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.profit_start')
                                "
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.profit_end')"
                          >
                           
                              <cleave
                                id="profit"
                                v-model.number="tradeForm.profitEnd"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.profit_end')
                                "
                              />
                        
                          </b-form-group>
                        </b-col>

                        <!-- order taken via -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.order_taken_via')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.order_taken_via')
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderTakenVia"
                                :options="optOrderTakenVias"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.select_order_taken_via'
                                  )
                                "
                                :reduce="(val) => val"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order taken through -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.order_given_through')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.order_given_through')
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderGivenThrough"
                                :options="optOrderGivenThroughs"
                                :clearable="false"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.select_order_given_through'
                                  )
                                "
                                :reduce="(val) => val"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      
                      </div>

                      <div class="d-flex">


                          <!-- tax -->
                          <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.tax_start')"
                            v-if="tradeForm.instrumentType == 'EQ'"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.tax_start')"
                              rules="min_value:0"
                            >
                              <b-form-input
                                id="tax"
                                v-model.number="tradeForm.tax"
                                name="tax"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.tax_start')"
                                autofocus
                                style="border: 1px solid #191970;"
                                @change="calculateClientAmountTax"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.tax_end')"
                            v-if="tradeForm.instrumentType == 'EQ'"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.tax_end')"
                              rules="min_value:0"
                            >
                              <b-form-input
                                id="tax"
                                v-model.number="tradeForm.taxEnd"
                                name="tax"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.tax_end')"
                                autofocus
                                style="border: 1px solid #191970;"
                                @change="calculateClientAmountTax"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>


                      </div>

                      <div>
                        <!-- note -->
                        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.note')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.note')"
                            >
                              <b-form-textarea
                                id="textarea-no-resize"
                                :placeholder="$t('equity_bond.trade_form.note')"
                                rows="3"
                                no-resize
                                v-model="tradeForm.note"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <div>
                        <!-- trade reporting needed -->
                        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="trade reporting needed"
                            >
                              <b-form-checkbox
                                v-model="tradeForm.tradeReportingNeeded"
                              >
                                {{
                                  $t(
                                    "equity_bond.trade_form.trade_reporting_needed"
                                  )
                                }}
                              </b-form-checkbox>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                    </b-card>
                  </b-col>

                  <!-- /******************************* Settlement Details***************************************************************** */ -->
                  <b-col cols="12" sm="12" mf="12" lg="12" xl="12" class="pr-0">
                    <b-card
                      title="Settlement Details"
                      style="width: 100% !important;"
                    >
                      <div class="d-flex">
                        <!-- UTI  -->
                        <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.uti')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.uti')"
                            >
                              <b-form-input
                                id="UTI"
                                v-model="tradeForm.uti"
                                name="UTI"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.uti')"
                                autofocus
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Unique Link ID -->
                        <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.unique_link_id')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.unique_link_id')
                              "
                            >
                              <b-form-input
                                id="uniqueLinkID"
                                v-model.number="tradeForm.uniqueLinkID"
                                type="number"
                                name="uniqueLinkID"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('equity_bond.trade_form.unique_link_id')
                                "
                                autofocus
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <div class="d-flex">
                        <!-- Trade Start  -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="trade start"
                            >
                              <flat-pickr
                                placeholder="Trade Start"
                                v-model="tradeForm.tradeStart"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                        <!-- Trade end -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="trade end"
                            >
                              <flat-pickr
                                placeholder="Trade End"
                                v-model="tradeForm.tradeEnd"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                      </div>
                      <div class="d-flex">
                        <!-- Settle Start  -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="settle start"
                            >
                              <flat-pickr
                                placeholder="Settle Start"
                                v-model="tradeForm.settleStart"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->
                        <!-- Settle end -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="settle end"
                            >
                              <flat-pickr
                                placeholder="Settle End"
                                v-model="tradeForm.settleEnd"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <div> -->
                        <!-- Trade Ref -->
                        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.trade_ref')"
                            >
                              <b-form-input
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="
                                  $t('equity_bond.trade_form.trade_ref')
                                "
                                autofocus
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                    </b-card>
                  </b-col>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="searchTradesloading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              <b>{{ $t("equity_bond.overlay_messages.searching_message") }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      {{/****END*** search for trades modal*/}}



      


    <template #modal-footer>

        <div class="w-100 d-flex justify-content-between align-items-center ">

          <b-col cols="12" md="6" sm="12" class="d-flex justify-content-start align-items-center">

            <b-form-checkbox

              v-model="showSearchInputs"

              class="float-right"

              style="margin-right:0.8rem"

            >

              {{ 'Save this search' }}

            </b-form-checkbox>

            <validation-observer ref="tradeSearchValidation" style="margin-right:0.5rem">

             

              <validation-provider

                #default="{ errors }"

                :name="$t('general_title.default_entry_name')"

                :rules="showSearchInputs ? 'required|min:3' : ''"

              >

                <b-form-input

                  v-if="showSearchInputs"

                  v-model="searchName"

                  placeholder="Enter search name"

                  id="searchName"

                  name="searchName"

                  class="float-right "

                  type="text"

                  autofocus

                  :state="errors.length > 0 ? false : null"

                />

              </validation-provider>

            </validation-observer>

           




            <button class="btn btn-secondary m-1" @click="saveSearch"  v-if="showSearchInputs">Save this search</button>

          </b-col>




          <b-col cols="6" md="6" sm="12" class="d-flex justify-content-end align-items-center">




            <b-dropdown text="Saved Searches" dropup right class="float-right mr-1">
              

              <b-dropdown-item
                v-for="(n, index) in savedSearchesList"

                :key="index"
                >
                <feather-icon
                class="mr-1"
                style="color:red;"
                @click="deleteSavedSearch(n.id)"
                icon="XIcon"
                size="12"
              />
                <span  @click="tradeForm = n.SearchData">{{ n.SearchName }} </span> 
           
            </b-dropdown-item>

              

            </b-dropdown>




            <button v-b-modal.modal-close_visit class="btn btn-secondary mr-1" @click="resetSearchForTradesModal">{{ $t('equity_bond.modals.modal_cancel') }}</button>

            <button v-b-modal.modal-close_visit class="btn btn-primary mr-1" @click="searchForTrades">{{ $t('equity_bond.modals.search_trade_modal_ok') }}</button>




           




             

          </b-col>

        </div>

      </template>

    
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,

} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import equityBondStoreModule from "./EquityBondStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,

  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const EQUITYBONDS_APP_STORE_MODULE_NAME = "equity&bond";
    // Register module
    if (!store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
      store.registerModule(
        EQUITYBONDS_APP_STORE_MODULE_NAME,
        equityBondStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EQUITYBONDS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {
    this.getSavedSearches();

  },

  props: {
     
    searchedCounterparties: {
      type: Array,
        default: function() {
        return [];
      }
    },
    searchedClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCostCenters: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCounterParties: {
      type: Array,
        default: function() {
        return [];
      }
    }
},


  data() {
    return {
      allEquityBondsData: [],
      searchForTradesModal: false,
      searchTradesloading: false,
      loadingSecurityDesc: false,
      loadingClient: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      orderDurationDataDisabled: true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      orderDateRange : null,
      tradeDateRange : null,
      settleDateRange : null,
      durationDateRange : null,
      cptyOrderDateRange : null,

      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell", "Sell Short"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optInstrumentTypes: ["EQ", "FI"],
      optEnableOCOs:["All Oco Orders","Enable Connected OCO Orders", "Disable Connected OCO Orders"],
      optSecurityDescriptions: [],
      optClientPriceTypes: [],
      optExchangeTrades: [],
      optCounterPartyTraders: [],

      optScfUsers: [],
      optActingCapacities: ["PRINCIPAL", "AGENT"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      searchName : null,
      optSearches : [],
       showSearchInputs : false,

      tradeForm: {
 
        costCenter:null,
        orderDurationDate: null,
        orderDurationDateEnd: null,

        orderDurationTime: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderDateEnd: null,

        orderTime: null,
        cptyOrderDate: null,
        cptyOrderDateEnd: null,

        cptyOrderTime: null,
        enableConnectedOCOOrders: 'All Oco Orders',
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        instrumentType: null,
        securityDesc: null,
        quantity: null,
        clientPrice: null,
        clientPriceType: null,
        amount: null,
        counterpartyPrice: null,
        clean: false,
        accrued: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded: null,
        counterparty: null,
        cptyAccount: null,
        counterpartyTrader: null,
        scfUser: null,
        actingCapaticy: null,
        tradeDate: null,
        tradeDateEnd: null,

        settlementDate: null,
        settlementDateEnd: null,

        executionTime: null,
        cptyExecutionTime: null,
        clientRate: null,
        clientCommission: null,
        clientAmount: null,
        counterpartyRate: null,
        tax: null,
        cptyCommission: null,
        counterpartyAmount: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        profit: null,
        tradeReportingNeeded: null,
        note: null,
        calculateCommissionFromRate1: true,
        calculateCommissionFromRate2: true,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,


        stopPriceEnd:null,
        trailingAmountEnd:null,
        takeProfitLimitEnd:null,
        stopLossStopEnd:null,
        stopLossLimitEnd:null,
        quantityEnd:null,
        clientPriceEnd:null,
        amountEnd:null,
        counterpartyPriceEnd:null,
        calculateCommissionFromRate1End:null,
        clientRateEnd:null,
        clientCommissionEnd:null,
        clientAmountEnd:null,
        calculateCommissionFromRate2End:null,
        counterpartyRateEnd:null,
        cptyCommissionEnd:null,
        counterpartyAmountEnd:null,
        profitEnd:null,
        taxEnd:null,

      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        mode:"range"
      },
      orderDateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
        mode:"range"
        // onChange: function(selectedDates, dateStr, instance) {
        //   console.log(instance)
        // }
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave6DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 6,
        },
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    savedSearchesList() {
      return this.optSearches;
    }
  },
  methods: {
    ...mapActions(["saveLastSearchEquityBond"]),

    deleteSavedSearch(id){
      store
        .dispatch("equity&bond/deleteSavedSearch", {
          auth: this.user,
          id: id,

        })
        .then((res) => {
            this.searchName = null;
            this.getSavedSearches()
            
            this.successMessage(res.data.message);

        
        })
        .catch((error) => {
          this.errorMessage(error);

          console.log(error);
        });
    },

    saveSearch() {

      store
        .dispatch("equity&bond/saveSearch", {
          auth: this.user,
          searchData: this.tradeForm,
          searchName: this.searchName,
          searchType: 'Equity',


        })
        .then((res) => {
            this.searchName = null;
            this.getSavedSearches()

            this.successMessage(res.data.message);
        })
        .catch((error) => {
          this.errorMessage(error);

          console.log(error);
        });
    },


    

    getSavedSearches() {
      store
        .dispatch("equity&bond/getSavedSearches",{SearchType: 'Equity',})
        .then((res) => {
            this.optSearches = res.data.savedSearches;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getArangeEquityBondTCUsers() {
      store
        .dispatch("equity&bond/fetchArangeEquityBondTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
            this.getArrangeEquityBondOrderTakenGivenThrough();
          } else if (res.data.info == "no_users") {
            this.errorMessage(this.$t('equity_bond.messages.user_error_no_users'));
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondOrderTakenGivenThrough() {
      store
        .dispatch(
          "equity&bond/fetchArrangeEquityBondOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(this.$t("equity_bond.messages.creating_message"));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondCurrencies() {
      store
        .dispatch("equity&bond/fetchArrangeEquityBondCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optClientPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchForTrades(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("EquityBond", "Search").then((res) => {
        if (res.data.valid) {
          //set and check search data
          let searchData = this.setSearchData();

          store
            .dispatch("equity&bond/searchEquityBond", {
              auth: this.user,
              searchData: searchData,
            })
            .then((res) => {
              this.$emit("searchedData", res.data);
              this.saveLastSearchEquityBond(searchData);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.searchForTradesModal = false;
          this.notAuthToastMessage();
        }
      });
    },
    searchSecuritydesc(search) {
      if (search.length >= 3) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingSecurityDesc = true;
          let securityTypeVal =
            this.tradeForm.instrumentType == "FI" ? "Bond" : "Equity";

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchSecuritydesc`, {
                auth: this.user,
                search: search,
                type: securityTypeVal,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optSecurityDescriptions = [];

                  res.data.resData.forEach(element => {

                    this.optSecurityDescriptions.push(
                    element.SecurityTicker
                  );
                    
                  });
                 this.tradeForm.securityDesc = res.data.resData[0].SecurityTicker
                  this.tradeForm.isin = res.data.resData[0].SecurityIsin;
                  this.tradeForm.exchangeTraded = res.data.resData[0].Exchange;
                  this.tradeForm.clientPriceType = res.data.resData[0].SecurityCcy;
                  this.loadingSecurityDesc = false;
                } else {
                  this.notFoundMessage("security desc.");
                  this.loadingSecurityDesc = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
  
    selectedClient(search) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;
    },
   
    selectedCounterparty(search) {
      console.log(this.searchedCounterparties)
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      let selecting = this.searchedCounterparties.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.cptyAccount = selecting.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
              let listTraders = []
              res.data.resTrader.forEach(element => {
                if(!listTraders.includes(element.Trader)){
                  listTraders.push(element.Trader)

                }

                
              });
              this.optCounterPartyTraders = listTraders
            }
          })
          .catch((error) => reject(error));
      });
    },
    checkEnableOCOInputs(input) {
      if (input == "takeProfitLimit") {
        if (this.tradeForm.takeProfitLimit > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.stopLossStop > 0) {
              if (
                this.tradeForm.takeProfitLimit < this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.take_profit_lower_stop_price")
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
            if (
              this.tradeForm.takeProfitLimit > 0 &&
              this.tradeForm.stopLossLimit > 0
            ) {
              if (
                this.tradeForm.takeProfitLimit < this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.take_profit_lower_limit_price")
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
          } else if (
            this.tradeForm.buySell == "Sell" ||
            this.tradeForm.buySell == "Sell Short"
          ) {
            if (this.tradeForm.stopLossStop > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.take_profit_greater_stop_price")
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
            if (
              this.tradeForm.takeProfitLimit > 0 &&
              this.tradeForm.stopLossLimit > 0
            ) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "equity_bond.messages.take_profit_greater_limit_price"
                  )
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t("equity_bond.messages.greater_then_zero_take_profit")
          );
        }
      } else if (input == "stopLossStop") {
        if (this.tradeForm.stopLossStop > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.stopLossStop > this.tradeForm.takeProfitLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.stop_loss_greater_profit_price")
                );
                this.tradeForm.stopLossStop = null;
              }
            }
          } else if (
            this.tradeForm.buySell == "Sell" ||
            this.tradeForm.buySell == "Sell Short"
          ) {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.stop_loss_lower_profit_price")
                );
                this.tradeForm.stopLossStop = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t("equity_bond.messages.greater_then_zero_stop_loss")
          );
        }
      } else if (input == "stopLossLimit") {
        if (this.tradeForm.stopLossLimit > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.stopLossLimit > this.tradeForm.takeProfitLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "equity_bond.messages.stop_loss_limit_greater_profit_price"
                  )
                );
                this.tradeForm.stopLossLimit = null;
              }
            }
          } else if (
            this.tradeForm.buySell == "Sell" ||
            this.tradeForm.buySell == "Sell Short"
          ) {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "equity_bond.messages.stop_loss_limit_lower_profit_price"
                  )
                );
                this.tradeForm.stopLossLimit = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t("equity_bond.messages.greater_then_zero_stop_loss_limit")
          );
        }
      }
    },
    calculateAmount() {
      if (this.tradeForm.quantity > 0 && this.tradeForm.clientPrice > 0) {
        if (this.tradeForm.instrumentType == "EQ") {
          let cal = this.tradeForm.quantity * this.tradeForm.clientPrice;
          this.tradeForm.amount = cal;
          this.tradeForm.clientAmount = cal;
          this.tradeForm.counterpartyAmount = cal;
        } else if (this.tradeForm.instrumentType == "FI") {
          let cal =
            (this.tradeForm.quantity * this.tradeForm.clientPrice) / 100;
          this.tradeForm.amount = cal;
          this.tradeForm.clientAmount = cal;
          this.tradeForm.counterpartyAmount = cal;
        }
      }
    },
    calculateClientComission() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.clientRate > -1
      ) {
        if (this.tradeForm.instrumentType == "EQ") {
          this.tradeForm.clientCommission =
            (this.tradeForm.amount * this.tradeForm.clientRate) / 10000;
        } else if (this.tradeForm.instrumentType == "FI") {
          let temp = this.tradeForm.amount;

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = temp + this.tradeForm.accrued;
          }

          this.tradeForm.clientCommission =
            (temp * this.tradeForm.clientRate) / 10000;
        }
        if (this.tradeForm.clientCommission > -1) {
          this.tradeForm.clientAmount = this.tradeForm.amount;
          this.tradeForm.clientAmount =
            this.tradeForm.counterpartyAmount + this.tradeForm.clientCommission;
          this.tradeForm.profit = this.tradeForm.clientCommission;
        }
      }
    },
    calculateCounterpartyComission() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.counterpartyRate > -1
      ) {
        if (this.tradeForm.instrumentType == "EQ") {
          this.tradeForm.cptyCommission =
            (this.tradeForm.amount * this.tradeForm.counterpartyRate) / 10000;
        } else if (this.tradeForm.instrumentType == "FI") {
          let temp = this.calculateConterpartyFaceValue();

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = temp + this.tradeForm.accrued;
          }

          this.tradeForm.cptyCommission =
            (temp * this.tradeForm.counterpartyRate) / 10000;
        }
        if (this.tradeForm.cptyCommission > -1) {
          this.tradeForm.counterpartyAmount = this.tradeForm.amount;
          this.tradeForm.counterpartyAmount =
            this.tradeForm.counterpartyAmount + this.tradeForm.cptyCommission;
          this.tradeForm.profit =
            this.tradeForm.clientCommission +
            this.tradeForm.cptyCommission * -1;
        }
      }
    },
    calculateClientRate() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.clientCommission > -1
      ) {
        if (this.tradeForm.instrumentType == "EQ") {
          this.tradeForm.clientRate =
            (this.tradeForm.clientCommission / this.tradeForm.amount) * 10000;
        } else if (this.tradeForm.instrumentType == "FI") {
          let temp = this.tradeForm.amount;

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = temp + this.tradeForm.accrued;
          }

          this.tradeForm.clientRate =
            (this.tradeForm.clientCommission / temp) * 10000;
        }

        if (this.tradeForm.clientRate > -1) {
          this.tradeForm.clientAmount = this.tradeForm.amount;
          this.tradeForm.clientAmount =
            this.tradeForm.clientAmount + this.tradeForm.clientCommission;
          this.tradeForm.profit =
            this.tradeForm.clientCommission +
            this.tradeForm.cptyCommission * -1;
        }
      }
    },
    calculateCounterpartyRate() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.cptyCommission > -1
      ) {
        if (
          this.tradeForm.amount > 0 &&
          this.tradeForm.instrumentType == "EQ"
        ) {
          this.tradeForm.counterpartyRate =
            (this.tradeForm.cptyCommission / this.tradeForm.amount) * 10000;
        } else if (this.tradeForm.instrumentType == "FI") {
          let temp = this.calculateConterpartyFaceValue();

          if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
            temp = temp + this.tradeForm.accrued;
          }

          this.tradeForm.counterpartyRate =
            (this.tradeForm.cptyCommission / temp) * 10000;
        }

        if (this.tradeForm.counterpartyRate > -1) {
          this.tradeForm.counterpartyAmount = this.tradeForm.amount;
          this.tradeForm.counterpartyAmount =
            this.tradeForm.counterpartyAmount + this.tradeForm.cptyCommission;
          this.tradeForm.profit =
            this.tradeForm.clientCommission +
            this.tradeForm.cptyCommission * -1;
        }
      }
    },
    calculateConterpartyFaceValue() {
      if (this.tradeForm.quantity > 0) {
        if (
          this.tradeForm.clientPrice > 0 &&
          this.tradeForm.instrumentType == "EQ"
        ) {
          return this.tradeForm.quantity * this.tradeForm.clientPrice;
        } else if (
          this.tradeForm.counterpartyPrice > 0 &&
          this.tradeForm.instrumentType == "FI"
        ) {
          return (
            (this.tradeForm.quantity * this.tradeForm.counterpartyPrice) / 100
          );
        } else {
          return 0;
        }
      }
    },
    calculateClientAmountTax() {
      let temp = 0;

      if (this.tradeForm.amount > 0) {
        temp = temp + this.tradeForm.amount;
      }

      if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
        if (this.tradeForm.instrumentType == "FI") {
          temp = temp + this.tradeForm.accrued;
        }
      }

      if (this.tradeForm.clientCommission > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = temp + this.tradeForm.clientCommission;
        } else {
          temp = temp - this.tradeForm.clientCommission;
        }
      }

      if (this.tradeForm.tax > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = temp + this.tradeForm.tax;
        } else {
          temp = temp - this.tradeForm.tax;
        }
      }

      if (temp > 0) {
        this.tradeForm.clientAmount = temp;
        this.tradeForm.counterpartyAmount = temp;
      } else {
        this.tradeForm.counterpartyAmount = null;
      }
    },
    calculateCounterpartyAmount() {
      let temp = 0;

      temp = temp + this.calculateConterpartyFaceValue();

      if (this.tradeForm.clean && this.tradeForm.accrued > 0) {
        if (this.tradeForm.instrumentType == "FI") {
          temp = temp + this.tradeForm.accrued;
        }
      }

      if (this.tradeForm.counterpartyComission > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = temp + this.tradeForm.counterpartyComission;
        } else {
          temp = temp - this.tradeForm.counterpartyComission;
        }
      }

      if (this.tradeForm.tax > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = temp + this.tradeForm.tax;
        } else {
          temp = temp - this.tradeForm.tax;
        }
      }

      if (temp > 0) {
        this.tradeForm.clientAmount = temp;
        this.tradeForm.counterpartyAmount = temp;
      } else {
        this.tradeForm.counterpartyAmount = null;
      }
    },
    calculateTCProfit() {
      let temp = 0;

      if (
        this.tradeForm.clientAmount > 0 &&
        this.tradeForm.counterpartyAmount > 0
      ) {
        if (this.tradeForm.buySell == "Buy") {
          this.tradeForm.profit =
            this.tradeForm.clientAmount - this.tradeForm.counterpartyAmount;
        } else {
          this.tradeForm.profit =
            -this.tradeForm.clientAmount + this.tradeForm.counterpartyAmount;
        }
      } else {
        this.tradeForm.profit = null;
      }
    },
    checkTraSettlCptyDate(type) {
      if (type == "Trade Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.tradeDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_before_order_date")
          );
          this.tradeForm.tradeDate = null;
        }
        if (this.tradeForm.instrumentType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t(
                "equity_bond.messages.trade_date_before_counterparty_order_date"
              )
            );
            this.tradeForm.tradeDate = null;
          }
        }
      } else if (type == "Settlement Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.settlementDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.settlementDate = null;
        }
        if (this.tradeForm.instrumentType == "FI") {
          if (
            Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
            Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
          ) {
            this.checkedTraSettlDateMessage(
              this.$t(
                "equity_bond.messages.settlement_date_before_counterparty_order_date"
              )
            );
            this.tradeForm.settlementDate = null;
          }
        }
      } else if ("Cpty Order Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "equity_bond.messages.trade_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "equity_bond.messages.settlement_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }
      }
    },
    checkTime(type) {
      if (type == "Order Time") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t("equity_bond.messages.order_time_later_execution_time")
            );
            this.tradeForm.orderTime = null;
          }
        }

        this.checkOrdersTime("Order Time");
      } else if ("Execution Time") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t("equity_bond.messages.execution_time_later_order_time")
            );
            this.tradeForm.executionTime = null;
          }
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          if (this.tradeForm.cptyOrderTime > this.tradeForm.executionTime) {
            this.checkTimeMessage(
              this.$t(
                "equity_bond.messages.execution_time_later_counterparty_order_time"
              )
            );
            this.tradeForm.executionTime = null;
          }
        }
      }
    },
    checkOrdersTime(type) {
      if (type == "Order Duration Time") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t("equity_bond.messages.order_duration_later_order_time")
            );
            this.tradeForm.orderDurationTime = null;
          }
        }
      } else if (type == "Order Time") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) ==
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          if (this.tradeForm.orderTime > this.tradeForm.orderDurationTime) {
            this.checkTimeMessage(
              this.$t(
                "equity_bond.messages.order_time_less_order_duration_time"
              )
            );
            this.tradeForm.orderTime = null;
          }
        }
      }
    },
    checkOrdersDate(type) {
      if (type == "Order Date") {
        if (this.tradeForm.timeInForce == "DAY") {
          this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.order_date_after_order_duration_date")
          );
          this.tradeForm.orderDate = null;
        }
      } else if (type == "Order Duration Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.order_duration_less_order_date")
          );
          this.tradeForm.orderDurationDate = null;
        }
      }
    },
    changeTimeInForceType() {
      if (this.tradeForm.timeInForce == "DAY") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = true;
        this.orderDurationTimeDisabled = true;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "GTC") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "GTD") {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate;
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString();
        this.orderDurationDataDisabled = false;
        this.orderDurationTimeDisabled = false;
        this.orderDurationInputsVisible = true;
      } else if (this.tradeForm.timeInForce == "FOK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      } else if (this.tradeForm.timeInForce == "FAK") {
        this.orderDurationInputsVisible = false;
        this.tradeForm.orderDurationDate = null;
        this.tradeForm.orderDurationTime = null;
      }
    },
    resetSearchForTradesModal() {
      this.searchForTradesModal = false;
      (this.optSecurityDescriptions = []),
        (this.optClientPriceTypes = []),
        (this.optExchangeTrades = []),
        (this.optCounterPartyTraders = []),
        (this.optScfUsers = []),
        (this.optOrderTakenVias = []),
        (this.optOrderGivenThroughs = []),
        (this.tradeForm = {
          costCenter:null,
          orderDurationDate: null,
          orderDurationDateEnd: null,

          orderDurationTime: null,
          entryType: null,
          buySell: null,
          orderType: null,
          timeInForce: null,
          orderDate: null,
          orderDateEnd: null,

          orderTime: null,
          cptyOrderDate: null,
          cptyOrderDateEnd: null,

          cptyOrderTime: null,
          enableConnectedOCOOrders: 'All Oco Orders',
          stopPrice: null,
          trailingAmount: null,
          takeProfitLimit: null,
          stopLossStop: null,
          stopLossLimit: null,
          instrumentType: null,
          prop: false,
          securityDesc: null,
          quantity: null,
          clientPrice: null,
          clientPriceType: null,
          amount: null,
          counterpartyPrice: null,
          clean: false,
          accrued: null,
          client: null,
          clientAccount: null,
          isin: null,
          exchangeTraded: null,
          counterparty: null,
          cptyAccount: null,
          counterpartyTrader: null,
          scfUser: null,
          actingCapaticy: null,
          tradeDate: null,
          tradeDateEnd: null,

          settlementDate: null,
          settlementDateEnd: null,

          executionTime: null,
          cptyExecutionTime: null,
          clientRate: null,
          clientCommission: null,
          clientAmount: null,
          counterpartyRate: null,
          tax: null,
          cptyCommission: null,
          counterpartyAmount: null,
          orderTakenVia: null,
          orderGivenThrough: null,
          profit: null,
          tradeReportingNeeded: null,
          note: null,
          calculateCommissionFromRate1: true,
          calculateCommissionFromRate2: true,
          uti: null,
          uniqueLinkID: null,
          tradeRef: null,
          tradeStart: null,
          tradeEnd: null,
          settleStart: null,
          settleEnd: null,

          stopPriceEnd:null,
        trailingAmountEnd:null,
        takeProfitLimitEnd:null,
        stopLossStopEnd:null,
        stopLossLimitEnd:null,
        quantityEnd:null,
        clientPriceEnd:null,
        amountEnd:null,
        counterpartyPriceEnd:null,
        calculateCommissionFromRate1End:null,
        clientRateEnd:null,
        clientCommissionEnd:null,
        clientAmountEnd:null,
        calculateCommissionFromRate2End:null,
        counterpartyRateEnd:null,
        cptyCommissionEnd:null,
        counterpartyAmountEnd:null,
        profitEnd:null,
        taxEnd:null,

        });
    },
    greaterThanZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `${val} must be greater than 0`,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedEnableOCOInputsMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("equity_bond.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedTraSettlDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTimeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    successMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Success",
          text: message,
          icon: "TickIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    setSearchData() {
      
      let setSearch = {
        costCenter : this.tradeForm.costCenter,
        instrumentType: this.tradeForm.instrumentType,
        tradeDate:
          this.tradeForm.tradeDate != null
            ? this.YMDdateFormat(this.tradeForm.tradeDate)
            : null,
            tradeDateEnd:
          this.tradeForm.tradeDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.tradeDateEnd)
            : null,
        settlementDate:
          this.tradeForm.settlementDate != null
            ? this.YMDdateFormat(this.tradeForm.settlementDate)
            : null,
          settlementDateEnd:
        this.tradeForm.settlementDateEnd != null
          ? this.YMDdateFormat(this.tradeForm.settlementDateEnd)
          : null,
        orderTime:
          this.tradeForm.orderTime != null
            ? this.timeFormat(this.tradeForm.orderTime)
            : null,
        executionTime:
          this.tradeForm.executionTime != null
            ? this.timeFormat(this.tradeForm.executionTime)
            : null,
        client: this.tradeForm.client,
        account: this.tradeForm.clientAccount,
        securityDesc: this.tradeForm.securityDesc,
        isin: this.tradeForm.isin,
        buySell: this.tradeForm.buySell,
        quantity: this.tradeForm.quantity != null ? this.tradeForm.quantity : null,
        clientAmount:
          this.tradeForm.clientAmount != null
            ? parseFloat(this.tradeForm.clientAmount.toFixed(2))
            : null,
        counterpartyRate:
          this.tradeForm.counterpartyRate != null
            ? parseFloat(this.tradeForm.counterpartyRate.toFixed(2))
            : null,
        comissionRate:
          this.tradeForm.clientRate != null
            ? parseFloat(this.tradeForm.clientRate.toFixed(2))
            : null,
        counterpartyComission:
          this.tradeForm.cptyCommission != null
            ? parseFloat(this.tradeForm.cptyCommission.toFixed(2))
            : null,
        commissionAmount:
          this.tradeForm.clientCommission != null
            ? parseFloat(this.tradeForm.clientCommission.toFixed(2))
            : null,
        counterpartyAmount:
          this.tradeForm.counterpartyAmount != null
            ? parseFloat(this.tradeForm.counterpartyAmount.toFixed(2))
            : null,
        counterpartyAccount: this.tradeForm.cptyAccount,
        counterpartyTrader: this.tradeForm.counterpartyTrader,
        counterpartyPrice:
          this.tradeForm.counterpartyPrice != null
            ? parseFloat(this.tradeForm.counterpartyPrice.toFixed(2))
            : null,
        accuredInterest:
          this.tradeForm.accrued != null
            ? parseFloat(this.tradeForm.accrued.toFixed(2))
            : null,
        ccy: this.tradeForm.clientPriceType,
        price:
          this.tradeForm.clientPrice != null
            ? parseFloat(this.tradeForm.clientPrice.toFixed(2))
            : null,
        amount:
          this.tradeForm.amount != null
            ? parseFloat(this.tradeForm.amount.toFixed(2))
            : null,
        counterparty: this.tradeForm.counterparty,
        tcUser: this.tradeForm.scfUser,
        orderTakenVia: this.tradeForm.orderTakenVia,
        orderGivenThrough: this.tradeForm.orderGivenThrough,
        orderPrice: null,
        exchangeTraded: this.tradeForm.exchangeTraded,
        traderNote: this.tradeForm.note,
        securityAccount: null,
        actingCapaticy: this.tradeForm.actingCapaticy,
        counterpartOrderTime:
          this.tradeForm.cptyOrderTime != null
            ? this.timeFormat(this.tradeForm.cptyOrderTime)
            : null,
        counterpartyExecutionTime:
          this.tradeForm.cptyExecutionTime != null
            ? this.timeFormat(this.tradeForm.cptyExecutionTime)
            : null,
        tax:
          this.tradeForm.tax != null
            ? parseFloat(this.tradeForm.tax.toFixed(2))
            : null,
        tcUti: this.tradeForm.uti,
        uniqueLinkId: this.tradeForm.uniqueLinkID,
        tcProfit:
          this.tradeForm.profit != null
            ? parseFloat(this.tradeForm.profit.toFixed(2))
            : null,
        entryType: this.tradeForm.entryType,
        orderType: this.tradeForm.orderType,
        timeInForce: this.tradeForm.timeInForce,
        stopPrice:
          this.tradeForm.stopPrice != null
            ? parseFloat(this.tradeForm.stopPrice.toFixed(2))
            : null,
        trailingAmount:
          this.tradeForm.trailingAmount != null
            ? parseFloat(this.tradeForm.tradeForm.toFixed(2))
            : null,
        takeProfit: null,
        stopLoss:
          this.tradeForm.stopLossStop != null
            ? parseFloat(this.tradeForm.stopLossStop.toFixed(2))
            : null,
        orderDate:
          this.tradeForm.orderDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDate)
            : null,
        orderDateEnd:
          this.tradeForm.orderDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.orderDateEnd)
            : null,
        counterpartyOrderDate:
          this.tradeForm.cptyOrderDate != null
            ? this.YMDdateFormat(this.tradeForm.cptyOrderDate)
            : null,
        counterpartyOrderDateEnd:
          this.tradeForm.cptyOrderDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.cptyOrderDateEnd)
            : null,
        orderDurationDate:
          this.tradeForm.orderDurationDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDate)
            : null,
        orderDurationDateEnd:
          this.tradeForm.orderDurationDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDateEnd)
            : null,
        orderDurationTime:
          this.tradeForm.orderDurationTime != null
            ? this.timeFormat(this.tradeForm.orderDurationTime)
            : null,
        enableOCO: this.tradeForm.enableConnectedOCOOrders,
        ocoType: null,
        takeProfitLimit:
          this.tradeForm.takeProfitLimit != null
            ? parseFloat(this.tradeForm.takeProfitLimit.toFixed(2))
            : null,
        stopLossLimit:
          this.tradeForm.stopLossLimit != null
            ? parseFloat(this.tradeForm.stopLossLimit.toFixed(2))
            : null,
        ocoLink: null,
        orderQuantity: null,
        future4: null,
        future5: null,
        future6: null,
        future7: null,
        future8: null,
        future9: null,
        future10: null,
        future11: null,
        future12: null,
        future13: null,
        future14: null,
        future15: null,
        future16: null,
        startTradeDate:
          this.tradeForm.tradeStart != null
            ? this.YMDdateFormat(this.tradeForm.tradeStart)
            : null,
        endTradeDate:
          this.tradeForm.tradeEnd != null
            ? this.YMDdateFormat(this.tradeForm.tradeEnd)
            : null,
        startSettlementDate:
          this.tradeForm.settleStart != null
            ? this.YMDdateFormat(this.tradeForm.settleStart)
            : null,
        endSettlementDate:
          this.tradeForm.settleEnd != null
            ? this.YMDdateFormat(this.tradeForm.settleEnd)
            : null,
        ID: 0,

        stopPriceEnd:this.tradeForm.stopPriceEnd,
        trailingAmountEnd:this.tradeForm.trailingAmountEnd,
        takeProfitLimitEnd:this.tradeForm.takeProfitLimitEnd,
        stopLossEnd:this.tradeForm.stopLossStopEnd,
        stopLossLimitEnd:this.tradeForm.stopLossLimitEnd,
        quantityEnd:this.tradeForm.quantityEnd,
        priceEnd:this.tradeForm.clientPriceEnd,
        amountEnd:this.tradeForm.amountEnd,
        counterpartyPriceEnd:this.tradeForm.counterpartyPriceEnd,
        calculateCommissionFromRate1End:this.tradeForm.calculateCommissionFromRate1End,
        comissionRateEnd:this.tradeForm.clientRateEnd,
        commissionAmountEnd:this.tradeForm.clientCommissionEnd,
        clientAmountEnd:this.tradeForm.clientAmountEnd,
        calculateComissionFromRate2End:this.tradeForm.calculateCommissionFromRate2End,
        counterpartyRateEnd:this.tradeForm.counterpartyRateEnd,
        counterpartyComissionEnd:this.tradeForm.cptyCommissionEnd,
        counterpartyAmountEnd:this.tradeForm.counterpartyAmountEnd,
        tcProfitEnd:this.tradeForm.profitEnd,
        taxEnd:this.tradeForm.taxEnd,

      };

      return setSearch;
    },
  },
  watch: {
    searchForTradesModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeEquityBondTCUsers();
          this.getArrangeEquityBondCurrencies();
        }
      },
    },
    "tradeForm.instrumentType": {
      handler: function(val, before) {
        if (before != null) {
          this.calculateAmount();
          this.calculateClientAmountTax();
          this.calculateCounterpartyAmount();
          this.calculateTCProfit();
        }
      },
      deep: true,
    },

    orderDateRange: {
      handler: function(val, before) {
        if (val) {
          if(val.includes('to')){
            this.tradeForm.orderDate = val.split('to')[0]
            this.tradeForm.orderDateEnd = val.split('to')[1]

          }
          else{
            this.tradeForm.orderDate = val
          }
        }
      },
    },

    tradeDateRange: {
      handler: function(val, before) {
        if (val) {
          if(val.includes('to')){
            this.tradeForm.tradeDate = val.split('to')[0]
            this.tradeForm.tradeDateEnd = val.split('to')[1]

          }
          else{
            this.tradeForm.tradeDate = val
          }
        }
      },
    },

    settleDateRange: {
      handler: function(val, before) {
        if (val) {
          if(val.includes('to')){
            this.tradeForm.settlementDate = val.split('to')[0]
            this.tradeForm.settlementDateEnd = val.split('to')[1]

          }
          else{
            this.tradeForm.settlementDate = val
          }
          
        }
      },
    },

    durationDateRange: {
      handler: function(val, before) {
        if (val) {
          if(val.includes('to')){
            this.tradeForm.orderDurationDate = val.split('to')[0]
            this.tradeForm.orderDurationDateEnd = val.split('to')[1]

          }
          else{
            this.tradeForm.orderDurationDate = val
          }
          
        }
      },
    },

    cptyOrderDateRange: {
      handler: function(val, before) {
        if (val) {
          if(val.includes('to')){
            this.tradeForm.cptyOrderDate = val.split('to')[0]
            this.tradeForm.cptyOrderDateEnd = val.split('to')[1]

          }
          else{
            this.tradeForm.cptyOrderDate = val
          }
          
        }
      },
    },


  },
};
</script>

<style>
#searchTradeModal .modal-header .modal-title {
  color: white !important;
}

.eq_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #191970;
}

.fi_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #cd853f;
}



</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
